import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0dba08a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tm-field-associate--subheader caption-12"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex lh-1 mw-100pr"
}
const _hoisted_3 = { class: "d-flex flex-column justify-space-between overflow-x-clip" }
const _hoisted_4 = { class: "lh-1 truncate overflow-x-clip" }
const _hoisted_5 = { class: "distinct--text truncate overflow-x-clip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_chips = _resolveComponent("tm-chips")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.autocompleteDropdown ? 'FieldAutocompleteDropdown' : 'FieldAutocomplete'), _mergeProps({
    ref: "fieldAssociate",
    "model-value": _ctx.modelValue,
    options: _ctx.options,
    multiple: "",
    class: ["tm-field-associate", {
      'tm-field-associate--hide-input': !_ctx.isFocused && _ctx.modelValue?.length && !_ctx.autocompleteDropdown
    }],
    "start-search-from": 1,
    "option-search": ['name', 'description'],
    "item-value": "name",
    "option-auto-height": "",
    "option-disable": "notForSearch",
    "hide-dropdown-icon": ""
  }, _ctx.autocompleteDropdown ? _ctx.dropdownAttrs : {}, {
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onFocus: _ctx.focus,
    onBlur: _ctx.blur
  }), _createSlots({
    "selected-item": _withCtx(({ opt, index }) => [
      _createVNode(_component_tm_badge, {
        close: _ctx.isFocused,
        "is-link": "",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
        onClickClose: ($event: any) => (_ctx.removeAssociation(index)),
        onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.fieldAssociate && _ctx.fieldAssociate.focus()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: opt.icon
          }, null, 8, ["name"]),
          _createElementVNode("span", null, _toDisplayString(opt.name), 1)
        ]),
        _: 2
      }, 1032, ["close", "onClickClose"])
    ]),
    option: _withCtx(({ opt }) => [
      (opt.subheader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(opt.text), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_tm_avatar, {
              url: opt.avatar,
              name: opt.showAvatarSymbols ? opt.name : '',
              color: opt.color,
              "custom-icon": opt.icon,
              "custom-size": "32px",
              "placeholder-custom-size": "14px",
              class: "mr-2"
            }, null, 8, ["url", "name", "color", "custom-icon"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h5", _hoisted_4, _toDisplayString(opt.name), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(opt.description), 1)
            ])
          ]))
    ]),
    _: 2
  }, [
    (_ctx.autocompleteDropdown && _ctx.isFocused)
      ? {
          name: "selected",
          fn: _withCtx(({ selected }) => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selected, (opt, key) => {
              return (_openBlock(), _createBlock(_component_tm_badge, {
                key: key,
                label: opt.name,
                icon: opt.icon,
                close: "",
                "margin-x-on": "",
                class: _normalizeClass({'mr-0': key + 1 === selected.length}),
                onClickClose: ($event: any) => (_ctx.removeAssociation(+key))
              }, null, 8, ["label", "icon", "class", "onClickClose"]))
            }), 128))
          ])
        }
      : {
          name: "selected",
          fn: _withCtx(({ options }) => [
            _withDirectives(_createVNode(_component_tm_chips, {
              ref: "tmChips",
              items: options,
              "lines-count": _ctx.isFocused ? 3 : 1,
              size: "large",
              class: "mr-2 ml-2",
              close: "",
              "disable-toggler": "",
              onClickClose: ($event: any) => (_ctx.removeAssociation(options.findIndex(item => item.name === $event.name)))
            }, null, 8, ["items", "lines-count", "onClickClose"]), [
              [_vShow, _ctx.modelValue.length]
            ])
          ])
        },
    (_ctx.isFocused && _ctx.$slots['prepend'])
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend", _normalizeProps(_guardReactiveProps({ isFocused: _ctx.isFocused })), undefined, true)
          ])
        }
      : undefined
  ]), 1040, ["model-value", "options", "class", "onFocus", "onBlur"]))
}