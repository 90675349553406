
import type { PropType } from 'vue'
import { defineComponent, ref, computed, onMounted } from 'vue'
import kebabCase from 'lodash/kebabCase'

export type FieldTextSize = 'xSmall' | 'small' | 'medium' | ''

export default defineComponent({
  name: 'FieldText',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    leftIcon: {
      type: String,
    },
    leftIconClass: {
      type: String,
    },
    leftIconSize: {
      type: String,
      default: 'xLarge',
    },
    rightIcon: {
      type: String,
    },
    fieldClass: {
      type: String,
    },
    autoselect: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    errorHint: {
      type: String,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    domainText: {
      type: String,
    },
    size: {
      type: String as PropType<FieldTextSize>,
      default: '',
    },
    sidebarPaddings: {
      type: Boolean,
    },
    autofocus: {
      type: Boolean,
    },
  },
  setup(props: {
    type: string;
    autoselect: boolean
  }) {
    const qInput = ref()
    const qInputValue = ref('')

    const isPasswordVisible = ref(false)
    const inputType = computed(() => {
      if (props.type !== 'password') return props.type
      return isPasswordVisible.value
        ? 'text'
        : 'password'
    })

    const passwordIcon = computed(() => isPasswordVisible.value ? 'visibility_off' : 'visibility')

    const switchInputType = () => {
      isPasswordVisible.value = !isPasswordVisible.value
      // set focus on input after button click (for correct modelValue on blur)
      qInput.value.focus()
    }

    // methods for using from parents
    const blur = () => {
      qInput.value.blur()
    }

    const select = () => {
      qInput.value.select()
    }

    const focus = () => {
      qInput.value.focus()
    }

    onMounted(() => {
      if (props.autoselect) {
        select()
      }
    })

    return {
      qInput,
      qInputValue,
      inputType,
      passwordIcon,
      switchInputType,
      isPasswordVisible,
      kebabCase,
      blur,
      select,
      focus,
    }
  },
})
