
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import { ColorPicker } from 'vue3-colorpicker'
import type { ColorInputWithoutInstance } from 'tinycolor2'
import tinycolor from 'tinycolor2'
import TmDropdown from '@/components/shared/TmDropdown.vue'

export default defineComponent({
  components: { ColorPicker, TmDropdown },
  props: {
    modelValue: {
      type: [String, Object] as PropType<ColorInputWithoutInstance>,
      required: true,
    },
    format: {
      type: String as PropType<'rgb' | 'hex'>,
      default: 'hex',
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const hexValue = computed(() => tinycolor(props.modelValue).toHexString().toUpperCase())

    return {
      hexValue,
    }
  },
})
