import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_chips = _resolveComponent("tm-chips")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_field_tags = _resolveComponent("field-tags")!

  return (_openBlock(), _createBlock(_component_field_tags, {
    ref: "fieldTagsList",
    "model-value": _ctx.modelValue,
    options: _ctx.options,
    placeholder: _ctx.placeholder,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = true)),
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFocused = false))
  }, _createSlots({
    "selected-item": _withCtx(({ opt }) => [
      _createVNode(_component_tm_badge, {
        label: opt.label,
        color: "primary",
        hint: opt.hint,
        icon: opt.icon,
        close: "",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
        onClickClose: ($event: any) => (_ctx.removeItem(opt))
      }, null, 8, ["label", "hint", "icon", "onClickClose"])
    ]),
    option: _withCtx(({ opt }) => [
      _createVNode(_component_tm_badge, {
        color: "primary",
        label: opt.label,
        hint: opt.hint,
        icon: opt.icon
      }, null, 8, ["label", "hint", "icon"])
    ]),
    _: 2
  }, [
    (!_ctx.isFocused && _ctx.modelValue.length)
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _createVNode(_component_tm_chips, {
              ref: "tmChips",
              class: "my-n2px",
              items: _ctx.modelValue,
              size: "large",
              color: "primary",
              "toggle-badge-size": "large"
            }, null, 8, ["items"])
          ])
        }
      : undefined
  ]), 1032, ["model-value", "options", "placeholder"]))
}