import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54f5c2f1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex lh-1 mw-100pr" }
const _hoisted_2 = { class: "d-flex flex-column justify-space-between overflow-x-clip" }
const _hoisted_3 = {
  key: 0,
  class: "distinct--text ellipsis"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_field_assignee = _resolveComponent("field-assignee")!

  return (_openBlock(), _createBlock(_component_field_assignee, {
    ref: "fieldAssignee",
    placeholder: _ctx.placeholder,
    "with-unassigned-option": false,
    "show-add-new": ""
  }, {
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_avatar, {
          url: opt.avatar,
          name: opt.fullName,
          color: opt.avatarColor,
          "placeholder-type": opt.placeholderType,
          "custom-size": "32px",
          "placeholder-custom-size": "14px",
          class: "mr-2"
        }, null, 8, ["url", "name", "color", "placeholder-type"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", {
            class: _normalizeClass(["ellipsis", opt.email ? 'lh-1' : 'lh-32'])
          }, _toDisplayString(opt.fullName), 3),
          (opt.email)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(opt.email), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["placeholder"]))
}