import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_field_autocomplete = _resolveComponent("field-autocomplete")!

  return (_openBlock(), _createBlock(_component_field_autocomplete, {
    ref: "fieldAutocompleteDropdown",
    "model-value": _ctx.modelValue,
    options: _ctx.options,
    multiple: "",
    class: "tm-field-autocomplete-dropdown",
    "start-search-from": _ctx.startSearchFrom,
    "popup-content-class": `q-menu-${_ctx.uid}`,
    "hide-dropdown-icon": "",
    autocomplete: "none",
    "menu-anchor": "top left",
    "menu-self": "top left",
    "menu-offset": _ctx.menuOffset,
    onPopupShow: _ctx.popupShow,
    onFilteredOptionsChanged: _ctx.filteredOptionsChanged,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event ? $event : [])))
  }, _createSlots({
    option: _withCtx(({ opt }) => [
      _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ opt })))
    ]),
    "add-option": _withCtx(() => [
      _renderSlot(_ctx.$slots, "add-option")
    ]),
    _: 2
  }, [
    (_ctx.$slots['selected'])
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "selected", _normalizeProps(_guardReactiveProps({ selected: _ctx.modelValue, oneLine: _ctx.oneLine })))
          ])
        }
      : {
          name: "selected-item",
          fn: _withCtx(({ opt, index }) => [
            _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({
          opt,
          removeItem: () => _ctx.removeItem(index),
        })), () => [
              (_openBlock(), _createBlock(_component_tm_badge, {
                key: index,
                icon: _ctx.selectedItemIcon || opt?.icon,
                close: "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
                onClickClose: ($event: any) => (_ctx.removeItem(index))
              }, {
                default: _withCtx(() => [
                  (_ctx.selectedItemIcon)
                    ? (_openBlock(), _createBlock(_component_tm_icon, {
                        key: 0,
                        name: _ctx.selectedItemIcon
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", { class: "truncate" }, _toDisplayString(_ctx.itemValue ? opt[_ctx.itemValue] : opt), 1)
                ]),
                _: 2
              }, 1032, ["icon", "onClickClose"]))
            ])
          ])
        },
    (_ctx.$slots['prepend'])
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend")
          ])
        }
      : undefined
  ]), 1032, ["model-value", "options", "start-search-from", "popup-content-class", "menu-offset", "onPopupShow", "onFilteredOptionsChanged"]))
}