
import { defineComponent } from 'vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { SizeProp, ChipSize } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    FieldSelect,
    TmStatus,
  },
  props: {
    statusSize: {
      type: String as SizeProp<ChipSize>,
    },
  },
})
