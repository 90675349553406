<template>
  <router-view
    v-slot="{ Component }"
    v-bind="{ ...$attrs, ...$props }"
  >
    <component :is="Component">
      <template
        v-for="(index, name) in $slots"
        v-slot:[name]="data"
      >
        <slot
          :name="name"
          v-bind="data"
        />
      </template>
    </component>
  </router-view>
</template>
