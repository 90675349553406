
import { defineComponent, ref } from 'vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import TmPriority from '@/components/shared/TmPriority.vue'

export default defineComponent({
  components: {
    FieldSelect,
    TmPriority,
  },
  props: {
    semiBold: {
      type: Boolean,
    },
  },
  setup() {
    const fieldSelect = ref()
    const blur = () => {
      fieldSelect.value.blur()
    }
    return {
      fieldSelect,
      blur,
    }
  },
})
