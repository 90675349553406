import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09791ad7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]
const _hoisted_2 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    ref: "qInput",
    modelValue: _ctx.qInputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.qInputValue) = $event)),
    type: _ctx.inputType,
    outlined: "",
    dense: "",
    class: _normalizeClass(["tm-field-text", {
      'tm-field-text--sidebar-paddings': _ctx.sidebarPaddings,
      [`tm-field-text--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      [_ctx.fieldClass]: _ctx.fieldClass,
    }]),
    autofocus: _ctx.autofocus
  }, _createSlots({
    hint: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.errorState)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "mb-1 body-text-regular-12 error--text",
              textContent: _toDisplayString(_ctx.errorHint)
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString(_ctx.hint), 1)
      ])
    ]),
    _: 2
  }, [
    (_ctx.leftIcon || _ctx.$slots.prepend)
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend", {}, () => [
              _createVNode(_component_tm_icon, {
                name: _ctx.leftIcon,
                class: _normalizeClass(_ctx.leftIconClass),
                size: _ctx.leftIconSize
              }, null, 8, ["name", "class", "size"])
            ], true)
          ])
        }
      : undefined,
    (_ctx.type === 'password' || _ctx.domainText || _ctx.rightIcon || _ctx.$slots.append)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "append", {
              switchInputType: _ctx.switchInputType,
              passwordIcon: _ctx.passwordIcon
            }, () => [
              (_ctx.rightIcon)
                ? (_openBlock(), _createBlock(_component_tm_icon, {
                    key: 0,
                    name: _ctx.rightIcon,
                    size: "xLarge"
                  }, null, 8, ["name"]))
                : (_ctx.type === 'password')
                  ? (_openBlock(), _createBlock(_component_tm_icon, {
                      key: 1,
                      name: _ctx.passwordIcon,
                      size: "xLarge",
                      class: _normalizeClass(["pointer", {
            'light--text': !_ctx.qInputValue
          }]),
                      onClick: _ctx.switchInputType
                    }, null, 8, ["name", "class", "onClick"]))
                  : (_ctx.domainText)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: "tm-field-text--domain",
                        textContent: _toDisplayString(_ctx.domainText)
                      }, null, 8, _hoisted_1))
                    : _createCommentVNode("", true)
            ], true)
          ])
        }
      : undefined
  ]), 1032, ["modelValue", "type", "autofocus", "class"]))
}