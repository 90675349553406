
import { defineComponent } from 'vue'
import useQuasar from 'quasar/src/composables/use-quasar.js';

export default defineComponent({
  setup() {
    const $q = useQuasar()

    // Add support for custom icons
    $q.iconMapFn = (iconName) => {
      if (iconName.startsWith('tmi-')) {
        return {
          cls: `tmi ${iconName}`,
        }
      }
    }

    return {}
  },
})
