import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "ml-1 light--text"
}
const _hoisted_2 = { class: "neutral--text" }
const _hoisted_3 = {
  key: 0,
  class: "light--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_field_select_search = _resolveComponent("field-select-search")!

  return (_openBlock(), _createBlock(_component_field_select_search, {
    options: _ctx.filteredCategories,
    "item-style": "regular",
    "option-label": ""
  }, {
    option: _withCtx(({ opt }) => [
      (!opt.noAvatar)
        ? (_openBlock(), _createBlock(_component_tm_avatar, {
            key: 0,
            url: opt.url,
            color: opt.avatarColor,
            name: opt.label,
            "placeholder-custom-size": "14px",
            class: "mr-2",
            size: "small"
          }, null, 8, ["url", "color", "name"]))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(opt.label), 1),
      (opt.email)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, " (" + _toDisplayString(opt.email) + ") ", 1))
        : _createCommentVNode("", true)
    ]),
    "selected-item": _withCtx(({ opt }) => [
      _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ opt })), () => [
        _createVNode(_component_tm_ellipsis, {
          class: _normalizeClass(["w100pr", {
            'light--text': opt.email,
          }])
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(opt.label), 1),
            (opt.email)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " (" + _toDisplayString(opt.email) + ") ", 1))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["class"])
      ])
    ]),
    _: 3
  }, 8, ["options"]))
}