import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withKeys as _withKeys, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_tm_dropdown_date_base = _resolveComponent("tm-dropdown-date-base")!

  return (_openBlock(), _createBlock(_component_tm_dropdown_date_base, {
    "model-value": _ctx.modelValue,
    "no-unset": _ctx.noUnset,
    options: _ctx.options,
    class: _normalizeClass(["tm-field-datepicker__wrap w100pr", {
      'tm-field-datepicker--sidebar-paddings': _ctx.sidebarPaddings,
      [`tm-field-datepicker--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
    }]),
    onBeforeHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.qInput.blur())),
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, {
    default: _withCtx(({ showMenu, closeMenu }) => [
      _createVNode(_component_q_input, _mergeProps({ ref: "qInput" }, _ctx.$attrs, {
        "model-value": _ctx.inputValue,
        outlined: "",
        dense: "",
        class: "tm-field-datepicker",
        "onUpdate:modelValue": _ctx.onManualInput,
        onKeydown: [
          _ctx.onKeyDown,
          _withKeys(closeMenu, ["enter"]),
          _withKeys(closeMenu, ["tab"])
        ],
        onClick: showMenu
      }), _createSlots({ _: 2 }, [
        (!_ctx.hideCalendarIcon)
          ? {
              name: "append",
              fn: _withCtx(() => [
                _createVNode(_component_tm_icon, {
                  name: "event",
                  size: "xLarge",
                  class: "pointer distinct--text",
                  onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                })
              ])
            }
          : undefined
      ]), 1040, ["model-value", "onUpdate:modelValue", "onKeydown", "onClick"])
    ]),
    _: 1
  }, 8, ["model-value", "no-unset", "options", "class"]))
}