import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00192015"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    ref: "qInput",
    type: "textarea",
    class: _normalizeClass(["tm-field-textarea", {
      [`tm-field-textarea--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      [_ctx.fieldClass]: _ctx.fieldClass,
    }]),
    style: _normalizeStyle({
      'max-height': _ctx.computedMaxHeight,
    })
  }, {
    hint: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.errorState)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "mb-1 body-text-regular-12 error--text",
              textContent: _toDisplayString(_ctx.errorHint)
            }, null, 8, _hoisted_1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, _toDisplayString(_ctx.hint), 1)
      ])
    ]),
    _: 1
  }, 8, ["class", "style"]))
}