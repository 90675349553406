import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bb7bc73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center mw-100pr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_field_autocomplete_dropdown = _resolveComponent("field-autocomplete-dropdown")!

  return (_openBlock(), _createBlock(_component_field_autocomplete_dropdown, {
    modelValue: _ctx.selectCountry,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectCountry) = $event)),
    options: _ctx.allCountries,
    "item-value": "name",
    "option-search": "name",
    "selected-item-size": "large",
    "no-results-text": "No countries found",
    class: "tm-field-multiple-countries"
  }, {
    "selected-item": _withCtx(({ opt, removeItem }) => [
      _createVNode(_component_tm_badge, {
        close: "",
        class: "mr-0 my-0",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
        onClickClose: ($event: any) => (removeItem(opt.id))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_country, {
            country: opt,
            size: "xSmall"
          }, null, 8, ["country"])
        ]),
        _: 2
      }, 1032, ["onClickClose"])
    ]),
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_country, {
          country: opt,
          size: "xSmall"
        }, null, 8, ["country"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "options"]))
}