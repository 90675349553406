import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_field_select_search = _resolveComponent("field-select-search")!

  return (_openBlock(), _createBlock(_component_field_select_search, {
    "model-value": _ctx.modelValue,
    "filter-function": _ctx.filterFunction,
    "subheader-style": "bold",
    "item-style": "regular",
    "option-label": "",
    options: _ctx.options,
    onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide'))),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, _createSlots({
    "selected-item": _withCtx(({ opt }) => [
      _renderSlot(_ctx.$slots, "selected-item", { opt: opt }, () => [
        (opt.id)
          ? (_openBlock(), _createBlock(_component_tm_country, {
              key: 0,
              country: opt,
              size: "small",
              class: "mr-10px"
            }, null, 8, ["country"]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass({
            'distinct--text': opt.disabled && !opt.subheader
          })
            }, _toDisplayString(opt.label), 3))
      ], true)
    ]),
    option: _withCtx(({ opt }) => [
      (opt.id)
        ? (_openBlock(), _createBlock(_component_tm_country, {
            key: 0,
            country: opt,
            size: "small",
            class: "mr-10px"
          }, null, 8, ["country"]))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass({
          'distinct--text': opt.disabled && !opt.subheader
        })
          }, _toDisplayString(opt.label), 3))
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
        ])
      }
    })
  ]), 1032, ["model-value", "filter-function", "options"]))
}