
import type { PropType } from 'vue'
import { defineComponent, ref, nextTick } from 'vue'
import TmBadgeEditable from '@/components/shared/TmBadgeEditable.vue'
import { getTextWidth } from '@/services/utils'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'

type editableBadgeObj = {
  text: string,
  valid: boolean
}

const __default__ = defineComponent({
  name: 'FieldEditableBadges',
  components: {
    TmBadgeEditable,
    FieldAutocomplete,
  },
  props: {
    modelValue: {
      type: Array as PropType<editableBadgeObj[]>,
    },
    placeholder: {
      type: String,
      default: '',
    },
    borderHidden: {
      type: Boolean,
      default: false,
    },
    itemRegexps: {
      type: Array as PropType<RegExp[]>,
      default: () => [],
    },
    // Matches any space, tab, newline, comma
    itemDividerRegexp: {
      type: RegExp,
      default: /[\s,]/g,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const fieldEditableBadges = ref()

    const removeItem = (removeIndex: number) => {
      context.emit('update:modelValue', props.modelValue?.filter((value, index) => index !== removeIndex))
    }

    const createNewModelValueObj = (newItemText: string) => ({
      text: newItemText,
      valid: props.itemRegexps.length ? props.itemRegexps.some(regExp => regExp.test(newItemText)) : true,
    })

    const addItem = (newItemText: string) => {
      // check for unique value
      if (!props.modelValue?.find(item => item.text === newItemText)) {
        fieldEditableBadges.value.add(createNewModelValueObj(newItemText))
      }
      fieldEditableBadges.value.updateInputValue('')
    }

    const inputValue = ref('')
    const onInput = async (inputString: string) => {
      inputValue.value = inputString
      const itemsArr = inputString.split(props.itemDividerRegexp)
      if (itemsArr.length > 1) {
        for (let i = 0; i < itemsArr.length; i++) {
          const newItemText = itemsArr[i]
          await nextTick()
          if (newItemText) addItem(newItemText)
        }
      }
    }

    const onBlur = () => {
      if (inputValue.value.trim()) {
        addItem(inputValue.value.trim())
      }
    }

    const updateBadgeText = (newText: string, badgeIndex: number) => {
      const newModelValue = props.modelValue?.slice()
      if (newModelValue === undefined) return
      // check for unique value
      if (!newModelValue.find(item => item.text === newText)) {
        newModelValue[badgeIndex] = createNewModelValueObj(newText)
      }
      context.emit('update:modelValue', newModelValue)
    }

    // set auto input width, minimal value is 100px + 15px (padding for any case)
    const inputWidth = ((getTextWidth(props.placeholder) > 100 ? getTextWidth(props.placeholder) : 100) + 15) + 'px'

    return {
      fieldEditableBadges,
      removeItem,
      onInput,
      onBlur,
      addItem,
      updateBadgeText,
      inputWidth,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "06342c4c": (_ctx.inputWidth)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__