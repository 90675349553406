import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-field-time", {
      'tm-field-time--focused': _ctx.isFocused,
      [`tm-field-time--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
    }])
  }, [
    _createElementVNode("input", {
      ref: "inputEl",
      class: "tm-field-time__input",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
    }, null, 544)
  ], 2))
}