
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch } from 'vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmUserPreview from '@/components/shared/TmUserPreview.vue'
import type { AssociateOption } from '@/mock/associateCategories'
import { emailRegexp } from '@/services/utils'
import TmChipPerson from '@/components/shared/TmChipPerson.vue'

export default defineComponent({
  name: 'FieldCarbonCopy',
  components: {
    TmChipPerson,
    TmUserPreview,
    FieldAutocomplete,
    TmTooltip,
    TmAvatar,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array as PropType<AssociateOption[]>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Add contacts or emails',
    },
    showPreview: {
      type: Boolean,
    },
    hideOptionDescription: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const fieldCarbonCopy = ref()
    const isFocused = ref(false)

    const removeItem = (removeIndex: number) => {
      context.emit('update:modelValue', props.modelValue?.filter((value, index) => index !== removeIndex))
    }

    const addContact = (newContact: string, done: any) => {
      if (emailRegexp.test(newContact)) {
        done({
          name: newContact,
          description: newContact,
        },
        'add-unique')
      }
    }

    const inputValue = ref('')
    const onInput = (newValue: string) => {
      inputValue.value = newValue
    }

    const onUpdate = (newValue: any) => {
      // handle null value from quasar
      if (!newValue) newValue = []
      // remove createNewContactOption objects from modelValue
      let fixedValue = newValue?.map((item:any) => {
        if (item.createNewContactOption) {
          return emailRegexp.test(item.text) ? { name: item.text } : null
        }
        return item
      })
      // remove null value in array if it exists
      fixedValue = fixedValue.filter((item:any) => item)
      // remove duplicated values
      fixedValue = [...new Map(fixedValue.map((item: any) => [item.name, item])).values()]
      context.emit('update:modelValue', fixedValue)
      fieldCarbonCopy.value.updateInputValue('')
    }

    const realFilteredOptionsCount = ref(props.options?.length - props.modelValue?.length)
    const onFilteredOptionsChanged = (newValue: any) => {
      // realFilteredOptionsCount.value = newValue.filter((item:any) => !item.createNewContactOption).length
    }

    const updatedOptions = computed(() => {
      const createNewContact = {
        text: inputValue.value,
        notForSearch: true,
        createNewContactOption: true,
      }
      // show create new contact option if there is no matches in options and input value is email
      return (emailRegexp.test(inputValue.value) && !realFilteredOptionsCount.value) ? [...props.options, createNewContact] : props.options
    })

    watch(
      () => props.modelValue,
      () => {
        fieldCarbonCopy.value.updateInputValue('')
      }
    )

    // public methods
    const focus = () => {
      isFocused.value = true
      fieldCarbonCopy.value.focus()
    }
    const blur = () => {
      isFocused.value = false
      fieldCarbonCopy.value.blur()
    }

    return {
      fieldCarbonCopy,
      isFocused,
      updatedOptions,
      emailRegexp,
      removeItem,
      onInput,
      onFilteredOptionsChanged,
      addContact,
      onUpdate,
      focus,
      blur,
    }
  },
})
