
import type { PropType } from 'vue'
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import type { FieldSelectSize } from '@/components/shared/field/FieldSelect.vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import { escapeText, stopAndPrevent } from '@/services/utils'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmInteractiveList from '@/components/shared/TmInteractiveList.vue'
import { keyCodes } from '@/definitions/_general/_data/keyCodes'

const { keyEnter, keySpace, keyDown, keyUp } = keyCodes

export default defineComponent({
  name: 'FieldSelectSearch',
  components: {
    TmInteractiveList,
    TmDropdownItem,
    TmDropdown,
    TmSearch,
    FieldSelect,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Object, String],
    },
    options: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
    },
    optionLabel: {
      type: String,
      default: 'label',
    },
    optionValue: {
      type: String,
      default: 'value',
    },
    filterPlaceholder: {
      type: String,
      default: 'Search',
    },
    filterFunction: {
      type: Function,
      default: (el: any, q: RegExp) => el.subheader || q.test(el.label),
    },
    autofocus: {
      type: Boolean,
    },
    size: {
      type: String as PropType<FieldSelectSize>,
      default: '',
    },
    message: {
      type: String,
    },
    menuMaxHeight: {
      type: String,
      default: '460px',
    },
    subheaderStyle: {
      type: String as PropType<'bold' | 'caption'>,
      default: 'bold',
    },
    itemStyle: {
      type: String as PropType<'regular' | 'semi-bold'>,
      default: 'regular',
    },
  },
  emits: ['hide', 'update:modelValue'],
  setup(props, context) {
    const dropdownEl = ref()
    const hasSelectedItemSlot = !!context.slots['selected-item']
    const filterValue = ref('')
    const menuWidth = ref('')
    const filteredOptions = computed(() => {
      if (!filterValue.value) return props.options
      const query = new RegExp(escapeText(filterValue.value), 'i')

      const filtered = props.options.filter((el: any) => props.filterFunction(el, query))
      return filtered.filter((el: any, i: number, arr: any[]) => { // remove empty subheadings
        const nextItem = arr[i + 1]
        const nextNotSubheader = !nextItem?.subheader

        return !el.subheader || (el.subheader && nextItem && nextNotSubheader)
      })
    })

    const selectFieldEl = ref()
    const filterEl = ref()
    const interactiveListEl = ref()
    const onKeydown = (e: KeyboardEvent) => {
      if (e.code === keyCodes.keyEsc) {
        filterEl.value.blur()
        return
      }
      interactiveListEl.value?.onKeydown(e)
    }

    const showDropdown = ref(false)

    const propsAndAttrs = computed(() => {
      const { optionLabel, optionValue } = props
      const { transparent } = context.attrs
      const className = context.attrs.class
      return {
        transparent,
        class: className,
        optionLabel,
        optionValue,
      }
    })

    const onSelect = (e: any) => {
      if (e.disabled) {
        return
      }
      selectFieldEl.value.hidePopup()
      selectFieldEl.value.blur()
      showDropdown.value = false
      context.emit('update:modelValue', e)
    }

    const onKeydownSelect = (e: any) => {
      if ([keyEnter, keySpace, keyUp, keyDown].includes(e.code)) {
        stopAndPrevent(e)
        showDropdown.value = true
      }
    }

    watch(
      () => showDropdown.value,
      (val) => {
        if (!val) {
          filterValue.value = ''
        }
      }
    )

    watch(
      () => filteredOptions.value,
      () => nextTick(dropdownEl.value.updatePosition)
    )

    onMounted(() => {
      if (props.autofocus) {
        showDropdown.value = true
      }
      menuWidth.value = selectFieldEl.value.$el.offsetWidth + 'px'
    })

    return {
      blur,
      dropdownEl,
      hasSelectedItemSlot,
      filterValue,
      filteredOptions,
      selectFieldEl,
      filterEl,
      interactiveListEl,
      onKeydown,
      showDropdown,
      onSelect,
      onKeydownSelect,
      menuWidth,
      propsAndAttrs,
    }
  },
})
