
import { defineComponent, ref } from 'vue'
import FieldAssignee from '@/components/shared/field/FieldAssignee.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmAvatar,
    FieldAssignee,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Add primary contact',
    },
  },
  setup() {
    const fieldAssignee = ref()
    const focus = () => {
      fieldAssignee.value.focus()
    }
    const blur = () => {
      fieldAssignee.value.blur()
    }
    return {
      fieldAssignee,
      focus,
      blur,
    }
  },
})
