
import { defineComponent, ref } from 'vue'
import FieldAutocompleteDropdown, { autocompleteDropdownProps } from '@/components/shared/field/FieldAutocompleteDropdown.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  name: 'AutocompleteDropdownBadges',
  components: {
    TmAvatar,
    TmBadge,
    FieldAutocompleteDropdown,
  },
  props: {
    ...autocompleteDropdownProps,
    itemValue: {
      type: String,
      default: 'name',
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const isFocused = ref(false)
    const fieldAutocompleteDropdownChips = ref()

    const focus = () => {
      fieldAutocompleteDropdownChips.value.focus()
      isFocused.value = true
    }

    const blur = () => {
      fieldAutocompleteDropdownChips.value.blur()
      isFocused.value = false
    }

    const removeItem = (removeIndex: number) => {
      context.emit('update:modelValue', props.modelValue?.filter((value, index) => index !== removeIndex))
    }

    return {
      fieldAutocompleteDropdownChips,
      isFocused,
      focus,
      blur,
      removeItem,
    }
  },
})
