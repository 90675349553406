
import type { PropType } from 'vue'
import { defineComponent, onMounted, ref } from 'vue'
import kebabCase from 'lodash/kebabCase'

type FieldTimeSize = '' | 'small'

export default defineComponent({
  name: 'FieldMinutes',
  props: {
    modelValue: {
      type: String,
      default: '00',
    },
    timeUnit: {
      type: String as PropType<'seconds' | 'minutes' | 'hours' | 'hours12'>,
      default: 'seconds',
    },
    size: {
      type: String as PropType<FieldTimeSize>,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const inputEl = ref()
    const internalValue = ref(props.modelValue || '00')
    const isFocused = ref(false)
    const validTime = {
      seconds: {
        regexp: /^([0-9])$|^([0-5][0-9])$/,
        max: '59',
      },
      minutes: {
        regexp: /^([0-9])$|^([0-5][0-9])$/,
        max: '59',
      },
      hours: {
        regexp: /^([0-9])$|^([0-1][0-9])$|^(2[0-3])$/, // 24-hour format
        max: '23',
      },
      hours12: {
        regexp: /^([0-9])$|^(1[0-2])$/,
        max: '12',
      },
    }

    const valid = (val: string) => val === '' || validTime[props.timeUnit].regexp.test(val)
    const isBigger = (val: string) => val.length > 2 || +val > +validTime[props.timeUnit].max

    const onInput = () => {
      if (isBigger(inputEl.value.value)) {
        internalValue.value = validTime[props.timeUnit].max
      } else if (valid(inputEl.value.value)) {
        internalValue.value = inputEl.value.value
      }
      inputEl.value.value = internalValue.value
    }

    const onFocus = () => {
      isFocused.value = true
      if (internalValue.value === '00') {
        internalValue.value = ''
      }
      inputEl.value.value = internalValue.value
    }

    const onBlur = () => {
      isFocused.value = false
      if (!internalValue.value) {
        internalValue.value = '00'
      } else if (internalValue.value.length === 1) {
        internalValue.value = `0${internalValue.value}`
      }
      inputEl.value.value = internalValue.value
      context.emit('update:modelValue', inputEl.value.value)
    }

    onMounted(() => {
      inputEl.value.value = internalValue.value
    })

    return {
      kebabCase,
      inputEl,
      isFocused,
      internalValue,
      onInput,
      onFocus,
      onBlur,
    }
  },
})
