
import { defineComponent, computed, ref, toRef } from 'vue'
import { Field as Validation } from 'vee-validate'

// components
import FieldCheckbox from '@/components/shared/field/FieldCheckbox.vue'
import FieldRadio from '@/components/shared/field/FieldRadio.vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import FieldText from '@/components/shared/field/FieldText.vue'
import FieldTextarea from '@/components/shared/field/FieldTextarea.vue'
import FieldToggle from '@/components/shared/field/FieldToggle.vue'
import FieldNumber from '@/components/shared/field/FieldNumber.vue'
import FieldColorPicker from '@/components/shared/field/FieldColorPicker.vue'
import FieldMultipleCountries from '@/components/shared/field/FieldMultipleCountries.vue'
import FieldAssignee from '@/components/shared/field/FieldAssignee.vue'
import FieldAssociate from '@/components/shared/field/FieldAssociate.vue'
import FieldPrimaryContact from '@/components/shared/field/FieldPrimaryContact.vue'
import FieldEditor from '@/components/shared/editor/FieldEditor.vue'

// custom selects
import FieldSelectCountry from '@/components/shared/field/customSelect/FieldSelectCountry.vue'
import FieldSelectOrganization from '@/components/shared/field/customSelect/FieldSelectOrganization.vue'
import FieldSelectStatus from '@/components/shared/field/customSelect/FieldSelectStatus.vue'
import FieldSelectTeams from '@/components/shared/field/customSelect/FieldSelectTeams.vue'
import FieldSelectWithIcon from '@/components/shared/field/customSelect/FieldSelectWithIcon.vue'
import FieldSelectTimezone from '@/components/shared/field/customSelect/FieldSelectTimezone.vue'
import FieldSelectPriority from '@/components/shared/field/customSelect/FieldSelectPriority.vue'
import FieldAutocompleteDropdownChips from '@/components/shared/field/autocompleteDropdowns/FieldAutocompleteDropdownChips.vue'
// select search
import FieldSelectSearch from '@/components/shared/field/FieldSelectSearch.vue'
import FieldSelectSearchCountry from '@/components/shared/field/customSelectSearch/FieldSelectSearchCountry.vue'
import FieldSelectSearchField from '@/components/shared/field/customSelectSearch/FieldSelectSearchField.vue'
import FieldSelectSearchCategory from '@/components/shared/field/customSelectSearch/FieldSelectSearchCategory.vue'
import FieldSelectSearchTimezone from '@/components/shared/field/customSelectSearch/FieldSelectSearchTimezone.vue'
import FieldSelectSearchAccount from '@/components/shared/field/customSelectSearch/FieldSelectSearchAccount.vue'
// custom tags
import FieldTagsList from '@/components/shared/field/customTags/FieldTagsList.vue'
import FieldTagsRecipient from '@/components/shared/field/customTags/FieldTagsRecipient.vue'
// rest
import FieldDatepicker from '@/components/shared/field/FieldDatepicker.vue'
import FieldTags from '@/components/shared/field/FieldTags.vue'
import FieldInviteByEmail from '@/components/shared/field/FieldInviteByEmail.vue'
import FieldInviteByPhone from '@/components/shared/field/FieldInviteByPhone.vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import FieldAutocompleteDropdown from '@/components/shared/field/FieldAutocompleteDropdown.vue'
import FieldPhone from '@/components/shared/field/FieldPhone.vue'
import FieldCarbonCopy from '@/components/shared/field/FieldCarbonCopy.vue'
import FieldEditableBadges from '@/components/shared/field/FieldEditableBadges.vue'
// native input
import FieldTime from '@/components/shared/field/FieldTime.vue'
import FieldTimepicker from '@/components/shared/field/FieldTimepicker.vue'
// loader
import TmSkeleton from '@/components/shared/TmSkeleton.vue'

type FieldType = {
  [key: string]: string;
}

const fieldTypes: FieldType = {
  // simple
  checkbox: 'FieldCheckbox',
  password: 'FieldText',
  radio: 'FieldRadio',
  colorpicker: 'FieldColorPicker',
  select: 'FieldSelect',
  text: 'FieldText',
  textarea: 'FieldTextarea',
  toggle: 'FieldToggle',
  number: 'FieldNumber',
  assignee: 'FieldAssignee',
  associate: 'FieldAssociate',
  primaryContact: 'FieldPrimaryContact',
  // custom selects
  multipleCountries: 'FieldMultipleCountries',
  selectCountry: 'FieldSelectCountry',
  selectSearch: 'FieldSelectSearch',
  selectTeams: 'FieldSelectTeams',
  selectPriority: 'FieldSelectPriority',
  selectWithIcon: 'FieldSelectWithIcon',
  selectTimezone: 'FieldSelectTimezone',
  selectSearchCountry: 'FieldSelectSearchCountry',
  selectSearchField: 'FieldSelectSearchField',
  selectOrganization: 'FieldSelectOrganization',
  selectStatus: 'FieldSelectStatus',
  selectSearchCategory: 'FieldSelectSearchCategory',
  selectSearchTimezone: 'FieldSelectSearchTimezone',
  selectSearchAccount: 'FieldSelectSearchAccount',
  // custom tags
  tagsList: 'FieldTagsList',
  tagsRecipient: 'FieldTagsRecipient',
  // rest
  datepicker: 'FieldDatepicker',
  timepicker: 'FieldTimepicker',
  carbonCopy: 'FieldCarbonCopy',
  tags: 'FieldTags',
  inviteByEmail: 'FieldInviteByEmail',
  inviteByPhone: 'FieldInviteByPhone',
  autocomplete: 'FieldAutocomplete',
  autocompleteDropdown: 'FieldAutocompleteDropdown',
  autocompleteDropdownChips: 'FieldAutocompleteDropdownChips',
  editableBadges: 'FieldEditableBadges',
  phone: 'FieldPhone',
  // native input
  time: 'FieldTime',
  // editor
  editor: 'FieldEditor',
}

export default defineComponent({
  name: 'TmField',
  components: {
    Validation,
    FieldAutocompleteDropdown,
    FieldAutocompleteDropdownChips,
    FieldCheckbox,
    FieldSelectStatus,
    FieldEditableBadges,
    FieldRadio,
    FieldSelect,
    FieldText,
    FieldTextarea,
    FieldToggle,
    FieldColorPicker,
    FieldNumber,
    FieldAssignee,
    FieldAssociate,
    FieldPrimaryContact,
    FieldDatepicker,
    FieldSelectCountry,
    FieldSelectPriority,
    FieldSelectOrganization,
    FieldSelectSearch,
    FieldSelectSearchCountry,
    FieldSelectSearchField,
    FieldSelectSearchCategory,
    FieldSelectSearchTimezone,
    FieldSelectSearchAccount,
    FieldTags,
    FieldTagsList,
    FieldTagsRecipient,
    FieldCarbonCopy,
    FieldInviteByEmail,
    FieldInviteByPhone,
    FieldAutocomplete,
    FieldPhone,
    FieldTime,
    FieldMultipleCountries,
    FieldTimepicker,
    FieldSelectTeams,
    FieldSelectWithIcon,
    FieldSelectTimezone,
    TmSkeleton,
    FieldEditor,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, Boolean, Date, Array, Object],
    },
    type: {
      type: String,
      default: 'text',
      validator: (val: string) => {
        const valid = Object.keys(fieldTypes).includes(val)
        if (!valid) throw new Error(`${val} field not exist in fieldTypes`)
        return true
      },
    },
    name: {
      type: String,
    },
    message: {
      type: String,
    },
    errorHint: {
      type: String,
    },
    hideMessage: {
      type: Boolean,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    warningState: {
      type: Boolean,
      default: false,
    },
    successState: {
      type: Boolean,
      default: false,
    },
    validators: {
      type: [String, Object],
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup(props) {
    const componentName = computed(() => fieldTypes[props.type])
    const errorMessage = computed(() => props.errorHint ? props.errorHint : `${props.name || 'This'} field is not valid`)

    const showError = (meta: any) => {
      return (meta.touched && !meta.valid) || props.errorState
    }

    const wrapperClass = toRef(props, 'class')
    const propsWithoutClass = computed(() => {
      const propsCopy = { ...props }
      delete propsCopy.class
      return propsCopy
    })

    const field = ref()
    const focus = () => {
      field.value.focus()
    }
    const blur = () => {
      field.value.blur?.()
    }

    return {
      componentName,
      wrapperClass,
      errorMessage,
      propsWithoutClass,
      showError,
      field,
      focus,
      blur,
    }
  },
})
