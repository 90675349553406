
import { defineComponent, ref, nextTick } from 'vue'
import TmChips from '@/components/shared/TmChips.vue'
import FieldTags from '@/components/shared/field/FieldTags.vue'
import TmBadge from '@/components/shared/TmBadge.vue'

export default defineComponent({
  name: 'FieldTagsList',
  components: {
    TmBadge,
    FieldTags,
    TmChips,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: 'Add lists',
    },
  },
  emits: ['update:modelValue', 'update:options'],
  setup(props, context) {
    const fieldTagsList = ref()
    const tmChips = ref()
    const isFocused = ref(false)

    const removeItem = (e: any) => {
      const val = [...props.modelValue].filter((el: any) => el.label !== e.label)
      context.emit('update:modelValue', val)

      if (!tmChips.value) { return }
      nextTick(() => {
        tmChips.value.updateVisibleChildren()
      })
    }

    const focus = () => {
      fieldTagsList.value.focus()
    }

    const blur = () => {
      fieldTagsList.value.blur()
    }

    return {
      focus,
      blur,
      fieldTagsList,
      isFocused,
      removeItem,
      tmChips,
    }
  },
})
