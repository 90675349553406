
import { defineComponent, ref, watch } from 'vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import FieldAutocompleteDropdown from '@/components/shared/field/FieldAutocompleteDropdown.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmBadges from '@/components/shared/TmBadges.vue'
import TmChip from '@/components/shared/TmChip.vue'
import TmChips from '@/components/shared/TmChips.vue'

export default defineComponent({
  name: 'FieldAssociate',
  components: {
    TmChips,
    TmChip,
    TmBadges,
    TmBadge,
    FieldAutocomplete,
    FieldAutocompleteDropdown,
    TmAvatar,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
    },
    autocompleteDropdown: {
      type: Boolean,
    },
    // size prop for autocompleteDropdown
    oneLine: {
      type: Boolean,
    },
    linesCount: {
      type: Number,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const fieldAssociate = ref()
    const isFocused = ref(false)

    const removeAssociation = (removeIndex: number) => {
      context.emit('update:modelValue', props.modelValue?.filter((value, index) => index !== removeIndex))
    }

    // attributes for FieldAutocompleteDropdown only
    const dropdownAttrs = {
      'one-line': props.oneLine,
      'selected-item-size': '',
      'selected-item-rounded': true,
      'selected-item-with-avatar': true,
    }

    watch(
      () => props.modelValue,
      () => {
        fieldAssociate.value.updateInputValue('')
      }
    )

    // public functions
    const focus = () => {
      isFocused.value = true
      fieldAssociate.value.focus()
    }
    const blur = () => {
      isFocused.value = false
      fieldAssociate.value.blur()
    }

    return {
      fieldAssociate,
      isFocused,
      removeAssociation,
      dropdownAttrs,
      focus,
      blur,
    }
  },
})
