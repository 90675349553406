
import { defineComponent, ref } from 'vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import { utcZonesShort } from '@/definitions/_general/calendar/utc'

const utcZones = utcZonesShort.map((item) => item.value)

export default defineComponent({
  components: {
    FieldAutocomplete,
  },
  props: {
    options: {
      type: Array,
      default: () => utcZones,
    },
    placeholder: {
      type: String,
      default: 'Add timezone',
    },
  },
  setup() {
    const fieldAutocomplete = ref()
    const blur = () => {
      fieldAutocomplete.value.blur()
    }
    return {
      fieldAutocomplete,
      blur,
    }
  },
})
