import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71c4cd2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex lh-1 mw-100pr"
}
const _hoisted_2 = { class: "d-flex flex-column justify-space-between overflow-x-clip" }
const _hoisted_3 = {
  key: 1,
  class: "word-break-all"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_user_preview = _resolveComponent("tm-user-preview")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_chip_person = _resolveComponent("tm-chip-person")!
  const _component_field_autocomplete = _resolveComponent("field-autocomplete")!

  return (_openBlock(), _createBlock(_component_field_autocomplete, {
    ref: "fieldCarbonCopy",
    "model-value": _ctx.modelValue,
    options: _ctx.updatedOptions,
    placeholder: _ctx.placeholder,
    multiple: "",
    class: _normalizeClass(["tm-field-carbon-copy", {
      'tm-field-carbon-copy--focused': _ctx.isFocused,
      'tm-field-carbon-copy--hide-input': !_ctx.isFocused && (_ctx.modelValue && _ctx.modelValue.length)
    }]),
    "start-search-from": 1,
    "option-search": ['name', 'description'],
    "hide-dropdown-icon": "",
    "onUpdate:modelValue": _ctx.onUpdate,
    onNewValue: _ctx.addContact,
    onInputValue: _ctx.onInput,
    onFocus: _ctx.focus,
    onBlur: _ctx.blur,
    onFilteredOptionsChanged: _ctx.onFilteredOptionsChanged
  }, _createSlots({
    "selected-item": _withCtx(({ opt, index }) => [
      (_openBlock(), _createBlock(_component_tm_chip_person, {
        key: index,
        label: opt.name,
        "avatar-color": opt.color,
        "avatar-url": opt.avatar,
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
        onClickClose: ($event: any) => (_ctx.removeItem(index))
      }, null, 8, ["label", "avatar-color", "avatar-url", "onClickClose"]))
    ]),
    option: _withCtx(({ opt }) => [
      (!opt.createNewContactOption)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_tm_avatar, {
              url: opt.avatar,
              name: opt.name,
              color: opt.color,
              "custom-size": "32px",
              "placeholder-custom-size": "14px",
              class: "mr-2"
            }, null, 8, ["url", "name", "color"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h5", {
                class: _normalizeClass(["ellipsis", _ctx.hideOptionDescription ? 'lh-32' : 'lh-1'])
              }, _toDisplayString(opt.name), 3),
              (!_ctx.hideOptionDescription)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(["distinct--text ellipsis", {
              'error--text': !opt.description
            }])
                  }, _toDisplayString(opt.description || 'No email address'), 3))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(opt.text) + " (New contact)", 1))
    ]),
    _: 2
  }, [
    (!_ctx.isFocused)
      ? {
          name: "selected",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (contact, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "avatar-wrapper",
                style: _normalizeStyle({
          'z-index': _ctx.modelValue.length - index
        })
              }, [
                _createVNode(_component_tm_avatar, {
                  url: contact.avatar,
                  name: _ctx.emailRegexp.test(contact.name) ? undefined : contact.name,
                  color: contact.color,
                  size: "small",
                  class: "d-block",
                  "custom-icon-class": "distinct--text"
                }, null, 8, ["url", "name", "color"]),
                (_ctx.showPreview)
                  ? (_openBlock(), _createBlock(_component_tm_user_preview, { key: 0 }))
                  : (_openBlock(), _createBlock(_component_tm_tooltip, { key: 1 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(contact.name), 1)
                      ]),
                      _: 2
                    }, 1024))
              ], 4))
            }), 128))
          ])
        }
      : undefined
  ]), 1032, ["model-value", "options", "placeholder", "class", "onUpdate:modelValue", "onNewValue", "onInputValue", "onFocus", "onBlur", "onFilteredOptionsChanged"]))
}