import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pt-2 caption-12"
}
const _hoisted_2 = {
  key: 1,
  class: "d-flex lh-1 mw-100pr"
}
const _hoisted_3 = { class: "d-flex flex-column justify-space-between overflow-x-clip" }
const _hoisted_4 = { class: "lh-1 truncate" }
const _hoisted_5 = { class: "distinct--text truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_field_autocomplete_dropdown = _resolveComponent("field-autocomplete-dropdown")!

  return (_openBlock(), _createBlock(_component_field_autocomplete_dropdown, {
    ref: "fieldAutocompleteDropdownChips",
    class: "tm-field-dropup-chips",
    "item-value": _ctx.itemValue,
    multiple: "",
    "option-search": ['name', 'description'],
    "start-search-from": 1,
    "option-auto-height": "",
    "option-disable": "notForSearch",
    "use-input": _ctx.isFocused || !_ctx.modelValue.length,
    "model-value": _ctx.modelValue,
    options: _ctx.options,
    placeholder: !(_ctx.isFocused && !_ctx.modelValue.length) ? '' : _ctx.$attrs.placeholder,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event ? $event : []))),
    onFocus: _ctx.focus,
    onBlur: _ctx.blur
  }, _createSlots({
    selected: _withCtx(({ selected }) => [
      _renderSlot(_ctx.$slots, "selected", {}, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(selected, (opt, index) => {
          return (_openBlock(), _createBlock(_component_tm_badge, {
            key: index,
            label: opt?.name,
            icon: opt?.icon,
            close: "",
            "margin-y-on": "",
            onClickClose: ($event: any) => (_ctx.removeItem(+index))
          }, null, 8, ["label", "icon", "onClickClose"]))
        }), 128))
      ])
    ]),
    option: _withCtx(({ opt }) => [
      (opt?.subheader)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(opt?.text), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_tm_avatar, {
              url: opt?.avatar,
              name: opt?.showAvatarSymbols ? opt?.name : '',
              color: opt?.color,
              "custom-icon": opt?.icon,
              "custom-size": "32px",
              "placeholder-custom-size": "14px",
              class: "mr-2"
            }, null, 8, ["url", "name", "color", "custom-icon"]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h5", _hoisted_4, _toDisplayString(opt?.name), 1),
              _createElementVNode("span", _hoisted_5, _toDisplayString(opt?.description), 1)
            ])
          ]))
    ]),
    _: 2
  }, [
    (_ctx.$slots['prepend'])
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend", _normalizeProps(_guardReactiveProps({ isFocused: _ctx.isFocused })))
          ])
        }
      : undefined
  ]), 1032, ["item-value", "use-input", "model-value", "options", "placeholder", "onFocus", "onBlur"]))
}