import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_field_select = _resolveComponent("field-select")!

  return (_openBlock(), _createBlock(_component_field_select, { options: _ctx.options }, _createSlots({
    "selected-item": _withCtx(({ opt }) => [
      _createVNode(_component_tm_country, {
        country: opt,
        size: _ctx.flagSize
      }, null, 8, ["country", "size"])
    ]),
    option: _withCtx(({ opt }) => [
      _createVNode(_component_tm_country, { country: opt }, null, 8, ["country"])
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
        ])
      }
    })
  ]), 1032, ["options"]))
}