
import type { PropType } from 'vue'
import { defineComponent, ref, computed } from 'vue'
import kebabCase from 'lodash/kebabCase'

type FieldTextareaSize = 'xSmall' | 'small' | 'medium' | 'large'

const __default__ = defineComponent({
  name: 'FieldTextarea',
  props: {
    fieldClass: {
      type: String,
    },
    hint: {
      type: String,
    },
    errorHint: {
      type: String,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<FieldTextareaSize>,
      default: 'xSmall',
    },
    maxHeight: {
      type: [String, Number],
    },
  },
  setup(props) {
    const qInput = ref()
    const focus = () => {
      qInput.value.focus()
    }

    const computedMaxHeight = computed(() => props.maxHeight ? `${props.maxHeight}px` : 'unset')

    return {
      qInput,
      focus,
      kebabCase,
      computedMaxHeight,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "74d9441e": (_ctx.computedMaxHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__