
import type { PropType } from 'vue'
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import _ from 'lodash'
import { detailsGridSidebarClass } from '@/definitions/_general/_data/general'
export type FieldSelectSize = 'xSmall' | 'small' | 'medium' | '' | 'float'

export default defineComponent({
  name: 'FieldSelect',
  props: {
    modelValue: {
      type: [String, Number, Array, Object],
    },
    options: {
      type: [Object, Array],
      default: () => [],
    },
    placeholder: {
      type: String,
    },
    useInput: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    hideNoResult: {
      type: Boolean,
    },
    optionAutoHeight: {
      type: Boolean,
      default: false,
    },
    popupContentClass: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    fieldClass: {
      type: String,
    },
    permanentPlaceholder: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    errorHint: {
      type: String,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    noResultsText: {
      type: String,
      default: 'No matching results',
    },
    size: {
      type: String as PropType<FieldSelectSize>,
      default: '',
    },
    sidebarPaddings: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
    },
    narrow: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur'],
  setup(props, context) {
    const dynamicOptRefs: any = {}
    for (let i = 0; i < props.options.length; i++) {
      dynamicOptRefs[`fieldSelectQItem${i}`] = ref()
    }
    const qSelect = ref()
    const isFocused = ref(false)
    const isInsideOfSidebar = ref()
    const hasSearch = ref(false)

    const removeOptionHoverClass = (opt: any, elem: any) => {
      if (!opt?.active) {
        dynamicOptRefs[elem].value?.$el?.classList.remove('q-manual-focusable--focused')
      }
    }

    const onFocus = () => {
      isFocused.value = true
      context.emit('focus')
      if (popupMaxWidth.value <= 0) {
        updatePopupMaxWidth()
      }
    }
    const onBlur = () => {
      isFocused.value = false
      context.emit('blur')
    }
    const onSearch = (search: string) => {
      hasSearch.value = !!search.length
    }

    // disallow to clear value if prop "clearable === false"
    watch(
      () => props.modelValue,
      (newValue, prevValue) => {
        if (!props.clearable && newValue === null) {
          context.emit('update:modelValue', prevValue)
        }
      })

    const popupMaxWidth = ref()
    const updatePopupMaxWidth = () => {
      // 8px is a scrollbar width
      popupMaxWidth.value = qSelect.value?.$el.clientWidth - 8
    }
    onMounted(() => {
      isInsideOfSidebar.value = !!qSelect.value?.$el.closest('.' + detailsGridSidebarClass)
      updatePopupMaxWidth()
      document.addEventListener('resize', updatePopupMaxWidth)
    })

    onUnmounted(() => {
      document.removeEventListener('resize', updatePopupMaxWidth)
    })

    // public methods
    const updateInputValue = (newValue: string) => {
      qSelect.value?.updateInputValue(newValue)
    }

    // will be used in future
    const updateMenuPosition = () => {
      qSelect.value?.updateMenuPosition()
    }

    const refreshMenu = () => {
      qSelect.value?.refresh()
    }

    const focus = () => {
      // don't update with the nextTick, work much better with setTimeout
      setTimeout(() => {
        qSelect.value?.focus()
      }, 0)
    }

    const blur = () => {
      // doesn't work without setTimeout
      setTimeout(() => {
        qSelect.value?.blur()
      }, 0)
    }

    const hidePopup = () => {
      qSelect.value?.hidePopup()
    }

    const showPopup = () => {
      // don't update with the nextTick, work much better with setTimeout
      setTimeout(() => {
        qSelect.value?.showPopup()
      }, 0)
    }

    const add = (opt: any) => {
      qSelect.value?.add(opt)
    }

    const scrollTo = (index: number) => {
      qSelect.value?.scrollTo(index)
    }

    const setOptionIndex = (index: number) => {
      qSelect.value?.setOptionIndex(index)
    }

    return {
      ...dynamicOptRefs,
      qSelect,
      isFocused,
      hasSearch,
      onFocus,
      onBlur,
      onSearch,
      removeOptionHoverClass,
      kebabCase: _.kebabCase,
      // methods for using from parents
      updateInputValue,
      updateMenuPosition,
      refreshMenu,
      focus,
      blur,
      hidePopup,
      showPopup,
      add,
      scrollTo,
      setOptionIndex,
      isInsideOfSidebar,
      popupMaxWidth,
    }
  },
})
