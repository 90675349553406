
import type { PropType } from 'vue'
import { defineComponent, toRefs, computed } from 'vue'
import FieldAutocompleteDropdown from '@/components/shared/field/FieldAutocompleteDropdown.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import { getCountriesList } from '@/services/countries/countries'
import type { FlagCountry } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    FieldAutocompleteDropdown,
    TmCountry,
    TmBadge,
  },
  props: {
    modelValue: {
      type: Array as PropType<FlagCountry[]>,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)
    const allCountries = getCountriesList()

    const selectCountry = computed({
      get(): FlagCountry[] {
        return modelValue.value
      },
      set(newValue: FlagCountry[]) {
        emit('update:modelValue', newValue)
      },
    })

    return {
      allCountries,
      selectCountry,
    }
  },
})
