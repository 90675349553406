import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_field_select_search = _resolveComponent("field-select-search")!

  return (_openBlock(), _createBlock(_component_field_select_search, {
    "model-value": _ctx.modelValue,
    "filter-function": _ctx.filterFunction,
    "subheader-style": "bold",
    "item-style": "regular",
    "option-label": "",
    options: _ctx.options,
    onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('hide'))),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, _createSlots({
    "selected-item": _withCtx(({ opt }) => [
      _renderSlot(_ctx.$slots, "selected-item", { opt: opt }, () => [
        _createVNode(_component_tm_icon, {
          name: opt.icon,
          size: "xLarge",
          class: "distinct--text mr-2"
        }, null, 8, ["name"]),
        _createElementVNode("span", null, _toDisplayString(opt.text), 1)
      ])
    ]),
    option: _withCtx(({ opt }) => [
      _createVNode(_component_tm_icon, {
        name: opt.icon,
        size: "xLarge",
        class: "distinct--text mr-2"
      }, null, 8, ["name"]),
      _createElementVNode("span", null, _toDisplayString(opt.text), 1)
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
        ])
      }
    })
  ]), 1032, ["model-value", "filter-function", "options"]))
}