import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed79d60c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mr-1" }
const _hoisted_2 = {
  key: 0,
  class: "body-text-regular-12 distinct--text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_field_autocomplete_dropdown = _resolveComponent("field-autocomplete-dropdown")!

  return (_openBlock(), _createBlock(_component_field_autocomplete_dropdown, {
    ref: "fieldTags",
    class: _normalizeClass(["tm-field-tags", {
      'tm-field-tags--sidebar-paddings': _ctx.sidebarPaddings,
      'tm-field-tags--hide-input': !_ctx.isFocused && _ctx.modelValue?.length,
    }]),
    "model-value": _ctx.modelValue,
    options: _ctx.options,
    "item-value": _ctx.itemValue,
    "start-search-from": 0,
    "one-line": _ctx.oneLine,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onInputValue: _ctx.onInput,
    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
    onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = false))
  }, _createSlots({
    option: _withCtx(({ opt }) => [
      _renderSlot(_ctx.$slots, "option", _normalizeProps(_guardReactiveProps({ opt })), undefined, true)
    ]),
    _: 2
  }, [
    (_ctx.$slots['selected'])
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "selected", _normalizeProps(_guardReactiveProps({ selected: _ctx.modelValue, oneLine: _ctx.oneLine })), undefined, true)
          ])
        }
      : {
          name: "selected-item",
          fn: _withCtx(({ opt, removeItem }) => [
            _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ opt, removeItem })), undefined, true)
          ])
        },
    (_ctx.showAddOption)
      ? {
          name: "add-option",
          fn: _withCtx(() => [
            _createVNode(_component_tm_button, {
              class: "add-option-button primary--text",
              "no-hover": "",
              "text-link": "",
              onClick: _ctx.addNewOption
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "add-option-button", {}, () => [
                  _createVNode(_component_tm_icon, {
                    name: "add",
                    class: "mr-2"
                  }),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.addOptionLabel), 1),
                  (_ctx.query)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(" + _toDisplayString(_ctx.query) + ")", 1))
                    : _createCommentVNode("", true)
                ], true)
              ]),
              _: 3
            }, 8, ["onClick"])
          ])
        }
      : undefined
  ]), 1032, ["class", "model-value", "options", "item-value", "one-line", "onInputValue"]))
}