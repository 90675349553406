
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    alignCenter: {
      type: Boolean,
      default: false,
    },
    fieldClass: {
      type: String,
    },
  },
})
