
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import FieldText from '@/components/shared/field/FieldText.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { escapeText } from '@/services/utils'

export default defineComponent({
  components: {
    FieldSelect,
    FieldText,
    TmButton,
  },
  props: {
    innerPlaceholder: {
      type: String,
      default: 'Search',
    },
    hideSearch: {
      type: Boolean,
    },
    buttonText: {
      type: String,
      default: 'Create new item',
    },
    itemsName: {
      type: String,
      default: 'items',
    },
    hideAddItem: {
      type: Boolean,
    },
    options: {
      type: [Object, Array],
      default: () => [],
    },
    filterOptions: {
      type: [String, Array] as PropType<string| string[]>,
      default: () => [],
    },
  },
  emits: ['create-item', 'update:dropdown'],
  setup(props) {
    const fieldSelectRef = ref()
    const searchValue = ref('')

    const filteredOptions = computed(() => {
      if (!searchValue.value) return props.options

      const query = new RegExp(escapeText(searchValue.value), 'i')
      return (props.options).filter((el: any) => {
        if (Array.isArray(props.filterOptions) && props.filterOptions.length) {
          const multipleValues = props.filterOptions.reduce((acc: string, item: string) => {
            return el[item] ? acc + el[item] : acc
          }, '')
          return query.test(multipleValues)
        }
        if (typeof props.filterOptions === 'string') {
          return query.test(el[props.filterOptions])
        }

        return query.test(el)
      })
    })
    const onArrowDown = () => {
      fieldSelectRef.value.setOptionIndex(0)
      fieldSelectRef.value.focus()
    }
    return {
      fieldSelectRef,
      onArrowDown,
      searchValue,
      filteredOptions,
    }
  },
})
