
import { defineComponent, ref, computed } from 'vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmBadges from '@/components/shared/TmBadges.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    FieldAutocomplete,
    TmBadge,
    TmBadges,
    TmAvatar,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    newTagText: {
      type: String,
      default: 'New team',
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const fieldSelectTeams = ref()
    const isFocused = ref(false)

    const removeTeam = (teamName: string) => {
      context.emit('update:modelValue', props.modelValue?.filter((item: any) => item.name !== teamName))
    }

    const createNewModelValueObj = (newTeamText: string) => ({
      name: newTeamText,
    })

    const addTeam = (newTeamText: string, done: any) => {
      done(createNewModelValueObj(newTeamText), 'add-unique')
    }

    const inputValue = ref('')
    const onInput = (newValue: string) => {
      inputValue.value = newValue
    }

    const onUpdate = (newValue: any) => {
      // handle null value from quasar
      if (!newValue) newValue = []
      // remove newTagOption objects from modelValue
      let fixedValue = newValue?.map((item:any) => {
        if (item.newTagOption) {
          return createNewModelValueObj(item.name)
        }
        return item
      })
      // remove null value in array if it exists
      fixedValue = fixedValue.filter((item:any) => item)
      // remove duplicated values
      fixedValue = [...new Map(fixedValue.map((item: any) => [item.name, item])).values()]
      context.emit('update:modelValue', fixedValue)
      fieldSelectTeams.value.updateInputValue('')
    }

    const updatedOptions = computed(() => {
      const createNewTag = {
        name: inputValue.value,
        notForSearch: true,
        newTagOption: true,
      }
      return inputValue.value ? [...props.options, createNewTag] : props.options
    })

    return {
      onUpdate,
      fieldSelectTeams,
      removeTeam,
      addTeam,
      onInput,
      isFocused,
      updatedOptions,
    }
  },
})
