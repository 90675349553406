import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59be774c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "lh-20" }
const _hoisted_3 = { class: "option-label" }
const _hoisted_4 = { class: "distinct--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_field_tags = _resolveComponent("field-tags")!

  return (_openBlock(), _createBlock(_component_field_tags, {
    class: _normalizeClass(["field-tags-list", { 'field-tags-list--white-borderless': _ctx.whiteBorderless }]),
    placeholder: _ctx.placeholder,
    "item-value": "label",
    "option-search": "label",
    "hide-no-result": "",
    clearable: false
  }, {
    "selected-item": _withCtx(({ opt, removeItem }) => [
      _createVNode(_component_tm_badge, {
        icon: opt.type === 'person' ? null : 'tmi-people',
        label: opt.label,
        hint: opt.shortHint || opt.hint,
        color: "blue",
        outline: "",
        close: "",
        onClickClose: removeItem
      }, null, 8, ["icon", "label", "hint", "onClickClose"])
    ]),
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_1, [
        (opt.type === 'person')
          ? (_openBlock(), _createBlock(_component_tm_avatar, {
              key: 0,
              name: opt.label,
              class: "mr-10px",
              color: opt.avatarColor,
              "custom-size": "44px",
              "placeholder-custom-size": "16px"
            }, null, 8, ["name", "color"]))
          : (_openBlock(), _createBlock(_component_tm_avatar, _mergeProps({ key: 1 }, _ctx.iconMap[opt.type], {
              name: opt.label,
              class: "mr-10px",
              "custom-size": "44px",
              "placeholder-custom-size": "22px",
              "placeholder-type": "icon"
            }), null, 16, ["name"])),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(opt.label), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(opt.hint), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["placeholder", "class"]))
}