
import { defineComponent, ref, watch, toRefs, computed } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { time12Regexp } from '@/services/utils'
import FieldText from '@/components/shared/field/FieldText.vue'

export default defineComponent({
  components: {
    TmDropdown,
    TmButton,
    FieldText,
  },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    hideClockIcon: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const inputField = ref()
    const menuIsVisible = ref(false)

    const inputTimeValue = ref('')

    const hour = ref(0)
    const minute = ref(0)
    const abbr = ref('AM')

    const minuteString = computed(() => (minute.value < 10) ? `0${minute.value}` : minute.value)

    watch(
      modelValue,
      (newValue) => {
        inputTimeValue.value = newValue
        const matchResult = newValue.match(time12Regexp)
        if (matchResult) {
          hour.value = Number(matchResult[1])
          minute.value = Number(matchResult[2])
          abbr.value = matchResult[3].replaceAll('.', '').toUpperCase()
        }
      }, {
        immediate: true,
      }
    )

    const updateAfterClick = () => {
      emit('update:modelValue', `${hour.value}:${minuteString.value} ${abbr.value}`)
    }

    const changeAbbr = () => {
      abbr.value = (abbr.value === 'AM') ? 'PM' : 'AM'
      updateAfterClick()
    }

    const moreHour = () => {
      if (hour.value === 12) {
        hour.value = 1
        return changeAbbr()
      }
      hour.value += 1
      updateAfterClick()
    }

    const lessHour = () => {
      if (hour.value === 1) {
        hour.value = 12
        return changeAbbr()
      }
      hour.value -= 1
      updateAfterClick()
    }

    const moreMinute = () => {
      if (minute.value === 59) {
        minute.value = 0
        return moreHour()
      }
      minute.value += 1
      updateAfterClick()
    }

    const lessMinute = () => {
      if (!minute.value) {
        minute.value = 59
        return lessHour()
      }
      minute.value -= 1
      updateAfterClick()
    }

    const pressEnterEvent = () => {
      inputField.value.blur()
      menuIsVisible.value = false
    }

    const checkTimeFormat = () => {
      const time = inputTimeValue.value.trim()

      if (time12Regexp.test(time)) {
        emit('update:modelValue', time)
      } else {
        inputTimeValue.value = modelValue.value
      }
    }

    return {
      inputField,
      menuIsVisible,
      inputTimeValue,
      checkTimeFormat,
      pressEnterEvent,
      hour,
      minuteString,
      abbr,
      moreHour,
      lessHour,
      moreMinute,
      lessMinute,
      changeAbbr,
    }
  },
})
