
import type { PropType } from 'vue'
import { computed, defineComponent } from 'vue'
import kebabCase from 'lodash/kebabCase'

type FieldNumberSize = 'xSmall' | 'small' | ''

export default defineComponent({
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    hideArrows: {
      type: Boolean,
    },
    fieldClass: {
      type: String,
    },
    withNegativeNumbers: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
    errorHint: {
      type: String,
    },
    errorState: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<FieldNumberSize>,
      default: '',
    },
    minValue: {
      type: Number,
    },
    maxValue: {
      type: Number,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const hasMinValue = typeof props.minValue === 'number'
    const hasMaxValue = typeof props.maxValue === 'number'
    const incrementValue = () => {
      const nextValue = +props.modelValue + 1
      if (hasMaxValue && nextValue > props.maxValue) {
        return
      }

      context.emit('update:modelValue', nextValue)
    }

    const decrementValue = () => {
      const nextValue = +props.modelValue - 1
      if (
        (hasMinValue && nextValue < props.minValue) ||
        (!props.withNegativeNumbers && !hasMinValue && nextValue < 0)
      ) {
        return
      }

      context.emit('update:modelValue', nextValue)
    }

    const checkValue = () => {
      if (props.modelValue) {
        if (hasMinValue && +props.modelValue < props.minValue) {
          context.emit('update:modelValue', props.minValue)
        }
        if (hasMaxValue && +props.modelValue > props.maxValue) {
          context.emit('update:modelValue', props.maxValue)
        }
      }
    }

    const propsAndAttrs = computed(() => {
      const { modelValue, size, ...otherProps } = props
      return {
        ...otherProps,
        ...context.attrs,
      }
    })

    return {
      incrementValue,
      decrementValue,
      checkValue,
      kebabCase,
      propsAndAttrs,
    }
  },
})
