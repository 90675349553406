import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, renderList as _renderList, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29d1596a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "px-4 pt-4 pb-2" }
const _hoisted_2 = { class: "px-4 py-3 divider d-flex" }
const _hoisted_3 = {
  key: 0,
  class: "px-4 pt-4 mb-n1"
}
const _hoisted_4 = { class: "px-4 pt-4 pb-2" }
const _hoisted_5 = { class: "px-4 py-3 divider d-flex" }
const _hoisted_6 = {
  key: 2,
  class: "no-results"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field_text = _resolveComponent("field-text")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_field_select = _resolveComponent("field-select")!

  return (_openBlock(), _createBlock(_component_field_select, {
    ref: "fieldSelectRef",
    options: _ctx.filteredOptions,
    onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:dropdown', true))),
    onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:dropdown', false)))
  }, _createSlots({
    "no-option": _withCtx(() => [
      (!_ctx.hideSearch)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_field_text, {
              modelValue: _ctx.searchValue,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchValue) = $event)),
              placeholder: _ctx.innerPlaceholder,
              size: "small",
              "left-icon": "search",
              clearable: "",
              autofocus: "",
              onKeydown: _withKeys(_ctx.onArrowDown, ["down"])
            }, null, 8, ["modelValue", "placeholder", "onKeydown"])
          ]))
        : _createCommentVNode("", true),
      (!_ctx.hideAddItem)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, " There are no " + _toDisplayString(_ctx.itemsName) + " yet ", 1),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_tm_button, {
                "text-link": "",
                class: "primary--text",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('create-item')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: "add",
                    class: "mr-2"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
                ]),
                _: 1
              })
            ])
          ], 64))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, " No matching results "))
    ]),
    _: 2
  }, [
    (!_ctx.hideSearch)
      ? {
          name: "before-options",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_field_text, {
                modelValue: _ctx.searchValue,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
                placeholder: _ctx.innerPlaceholder,
                size: "small",
                "left-icon": "search",
                clearable: "",
                autofocus: "",
                onKeydown: _withKeys(_ctx.onArrowDown, ["down"])
              }, null, 8, ["modelValue", "placeholder", "onKeydown"])
            ])
          ])
        }
      : undefined,
    (!_ctx.hideAddItem)
      ? {
          name: "after-options",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_tm_button, {
                "text-link": "",
                class: "primary--text",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('create-item')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_tm_icon, {
                    name: "add",
                    class: "mr-2"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
                ]),
                _: 1
              })
            ])
          ])
        }
      : undefined,
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
        ])
      }
    })
  ]), 1032, ["options"]))
}