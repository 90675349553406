import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field_autocomplete = _resolveComponent("field-autocomplete")!

  return (_openBlock(), _createBlock(_component_field_autocomplete, {
    ref: "fieldAutocomplete",
    options: _ctx.options,
    placeholder: _ctx.placeholder,
    "hide-dropdown-icon": ""
  }, null, 8, ["options", "placeholder"]))
}