
import { defineComponent } from 'vue'
import FieldSelectSearch from '@/components/shared/field/FieldSelectSearch.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { filterItemsByValues } from '@/services/utils'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'

export default defineComponent({
  name: 'selectSearchAccount',
  components: {
    FieldSelectSearch,
    TmButton,
  },
  props: {
    modelValue: {
      type: Object,
    },
    options: {
      type: Array,
      default: () => phoneCountryVariant,
    },
    buttonText: {
      type: String,
      default: 'Add new account',
    },
  },
  emits: ['hide', 'update:modelValue', 'create-item'],
  setup() {
    const filterFunction = (el: any, q: RegExp) => {
      if (el.subheader) return true
      const values = el.name ? [el.name] : [el.label]
      return filterItemsByValues(q, values)
    }

    return {
      filterFunction,
    }
  },
})
