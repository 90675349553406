import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fc8ade6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "truncate d-inline-block user-select-none" }
const _hoisted_2 = { class: "truncate d-inline-block" }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    ref: "qSelect",
    "model-value": _ctx.modelValue,
    "virtual-scroll-item-size": 40,
    "use-input": _ctx.useInput,
    multiple: _ctx.multiple,
    "input-class": _ctx.inputClass,
    outlined: "",
    dense: "",
    clearable: _ctx.clearable,
    class: _normalizeClass(["tm-field-select", [_ctx.inputClass, _ctx.fieldClass, {
      'hide-selected-value' : _ctx.isFocused && _ctx.hasSearch,
      'tm-field-select--sidebar-paddings': _ctx.sidebarPaddings,
      [`tm-field-select--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      'tm-field-select--narrow': _ctx.narrow,
    }]]),
    "popup-content-class": 'simple-select-dropdown ' + _ctx.popupContentClass + (_ctx.sidebarPaddings ? ' simple-select-dropdown--sidebar-paddings' : ''),
    label: (_ctx.modelValue || _ctx.useInput) ? undefined : _ctx.placeholder,
    placeholder: (_ctx.multiple ? (_ctx.modelValue?.length && !_ctx.permanentPlaceholder) : (_ctx.modelValue || !_ctx.useInput)) ? undefined : _ctx.placeholder,
    "transition-duration": 150,
    options: _ctx.options,
    behavior: "menu",
    "dropdown-icon": "tmi-arrow-drop-down-sharp",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onFocus: _ctx.onFocus,
    onBlur: _ctx.onBlur,
    onInputValue: _ctx.onSearch
  }, _createSlots({
    option: _withCtx((data) => [
      _createVNode(_component_q_item, _mergeProps({
        ref: 'fieldSelectQItem' + data.index
      }, data.itemProps, {
        class: {
          'q-item--subheader': data.opt.subheader,
          'q-item--border-top': data.opt.newContactOption || data.opt.borderTop,
          'q-item--autoheight': _ctx.optionAutoHeight,
          'q-item--inside-of-sidebar': _ctx.isInsideOfSidebar,
        },
        style: {
          maxWidth: _ctx.popupMaxWidth + 'px'
        },
        onMouseleave: ($event: any) => (_ctx.removeOptionHoverClass(data, 'fieldSelectQItem' + data.index))
      }), {
        default: _withCtx(() => [
          (!data.opt.subheader)
            ? _renderSlot(_ctx.$slots, "option", _normalizeProps(_mergeProps({ key: 0 }, data)), () => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(data?.opt[_ctx.$attrs['option-label']] || data?.opt?.text || data.opt), 1)
              ], true)
            : _renderSlot(_ctx.$slots, "option", _normalizeProps(_mergeProps({ key: 1 }, data)), undefined, true)
        ]),
        _: 2
      }, 1040, ["class", "style", "onMouseleave"])
    ]),
    "no-option": _withCtx((data) => [
      (!_ctx.hideNoResult)
        ? _renderSlot(_ctx.$slots, "no-option", _normalizeProps(_mergeProps({ key: 0 }, data)), () => [
            _createVNode(_component_q_item, _normalizeProps(_guardReactiveProps(data.itemProps)), {
              default: _withCtx(() => [
                _createVNode(_component_q_item_section, { class: "text-italic distinct--text text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.noResultsText), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 2
            }, 1040)
          ], true)
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "no-option-after", {}, undefined, true)
    ]),
    after: _withCtx(() => [
      _renderSlot(_ctx.$slots, "after", {}, undefined, true)
    ]),
    "before-options": _withCtx(() => [
      _renderSlot(_ctx.$slots, "before-options", {}, undefined, true)
    ]),
    "after-options": _withCtx(() => [
      _renderSlot(_ctx.$slots, "after-options", {}, undefined, true)
    ]),
    selected: _withCtx(() => [
      _renderSlot(_ctx.$slots, "selected", _normalizeProps(_guardReactiveProps({ options: _ctx.modelValue })), undefined, true)
    ]),
    hint: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.errorState)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "mb-1 body-text-regular-12 error--text",
              textContent: _toDisplayString(_ctx.errorHint)
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "hint", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.hint), 1)
          ], true)
        ])
      ])
    ]),
    _: 2
  }, [
    (!_ctx.$slots['selected'])
      ? {
          name: "selected-item",
          fn: _withCtx((data) => [
            _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps(data)), () => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(data?.opt[_ctx.$attrs['option-label']] || data?.opt?.text || data.opt), 1)
            ], true)
          ])
        }
      : undefined,
    (_ctx.$slots['prepend'])
      ? {
          name: "prepend",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "prepend", _normalizeProps(_guardReactiveProps({ hasSearch: _ctx.hasSearch })), undefined, true)
          ])
        }
      : undefined,
    (_ctx.$slots['append'])
      ? {
          name: "append",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "append", _normalizeProps(_guardReactiveProps({ hasSearch: _ctx.hasSearch })), undefined, true)
          ])
        }
      : undefined
  ]), 1032, ["model-value", "use-input", "multiple", "input-class", "clearable", "class", "popup-content-class", "label", "placeholder", "options", "onFocus", "onBlur", "onInputValue"]))
}