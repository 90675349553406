import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11fe1e2b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = { class: "flex-grow-1 truncate" }
const _hoisted_3 = { class: "body-text-semi-bold-14" }
const _hoisted_4 = { class: "lh-20 distinct--text" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badges = _resolveComponent("tm-badges")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_field_autocomplete = _resolveComponent("field-autocomplete")!

  return (_openBlock(), _createBlock(_component_field_autocomplete, {
    ref: "fieldSelectTeams",
    "model-value": _ctx.modelValue,
    options: _ctx.updatedOptions,
    multiple: "",
    "hide-dropdown-icon": "",
    class: _normalizeClass(["tm-field-select-teams", {
      'tm-field-select-teams--hide-input': !_ctx.isFocused && (_ctx.modelValue && _ctx.modelValue.length),
      'tm-field-select-teams--focused': _ctx.isFocused && (_ctx.modelValue && _ctx.modelValue.length)
    }]),
    "option-search": ['name'],
    "onUpdate:modelValue": _ctx.onUpdate,
    onNewValue: _ctx.addTeam,
    onInputValue: _ctx.onInput,
    onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocused = true)),
    onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isFocused = false))
  }, _createSlots({
    option: _withCtx(({ opt }) => [
      (!opt.newTagOption)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_tm_avatar, {
              url: opt.avatar,
              color: opt.color,
              name: opt.name,
              class: "mr-2",
              "custom-size": "32px"
            }, null, 8, ["url", "color", "name"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(opt.name), 1),
              _createElementVNode("div", _hoisted_4, _toDisplayString(opt.users) + " user" + _toDisplayString(opt.users > 1 ? 's' : ''), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(opt.name) + " (" + _toDisplayString(_ctx.newTagText) + ")", 1))
    ]),
    _: 2
  }, [
    (!_ctx.isFocused && _ctx.modelValue.length)
      ? {
          name: "selected",
          fn: _withCtx(() => [
            _createVNode(_component_tm_badges, {
              items: _ctx.modelValue,
              class: "my-0",
              close: "",
              onMousedown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fieldSelectTeams && _ctx.fieldSelectTeams.showPopup()))
            }, null, 8, ["items"])
          ])
        }
      : {
          name: "selected-item",
          fn: _withCtx(({ opt }) => [
            _createVNode(_component_tm_badge, {
              close: "",
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop","prevent"])),
              onClickClose: ($event: any) => (_ctx.removeTeam(opt.name))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(opt.name), 1)
              ]),
              _: 2
            }, 1032, ["onClickClose"])
          ])
        }
  ]), 1032, ["model-value", "options", "class", "onUpdate:modelValue", "onNewValue", "onInputValue"]))
}