
import { defineComponent, ref, watch } from 'vue'
import kebabCase from 'lodash/kebabCase'
import TmDropdownDateBase from '@/components/shared/dropdowns/TmDropdownDateBase.vue'
import { isInFormat, strToDate, formatDate } from '@/services/dateTimeService'
import { handleDateFormatSymbol } from '@/services/utils'
import { defaultDateFormat } from '@/definitions/_general/_data/general'
import type { SizeProp } from '@/definitions/shared/types'

export type FieldDatepickerSize = 'small' | ''

export default defineComponent({
  components: { TmDropdownDateBase },
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Date,
      default: null,
    },
    inputFormat: {
      type: String,
      default: defaultDateFormat,
    },
    onlyInputFormatSymbols: {
      type: Boolean,
    },
    sidebarPaddings: {
      type: Boolean,
    },
    hideCalendarIcon: {
      type: Boolean,
      default: false,
    },
    noUnset: {
      type: Boolean,
    },
    options: {
      type: Function,
      default: () => true,
    },
    size: {
      type: String as SizeProp<FieldDatepickerSize>,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const qInput = ref()
    const inputValue = ref('')

    const onManualInput = (value: string) => {
      if (!value || isInFormat(value, props.inputFormat)) {
        //  with noUnset empty date (null) is disallowed
        if (props.noUnset && !value) return
        // empty date (null) is allowed
        const newDatepickerDate = value ? strToDate(value, props.inputFormat) : null
        context.emit('update:modelValue', newDatepickerDate)
      }
    }

    const onKeyDown = (event: KeyboardEvent) => {
      return props.onlyInputFormatSymbols ? handleDateFormatSymbol(props.inputFormat, event) : true
    }

    const setValue = (value: Date | null) => {
      inputValue.value = value ? formatDate(value, props.inputFormat) : ''
    }

    watch(
      () => props.modelValue,
      (newValue) => setValue(newValue),
      { immediate: true }
    )

    return {
      kebabCase,
      qInput,
      inputValue,
      onManualInput,
      onKeyDown,
    }
  },
})
