import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field_select = _resolveComponent("field-select")!

  return (_openBlock(), _createBlock(_component_field_select, {
    ref: "fieldAutocomplete",
    "model-value": _ctx.keepEmpty ? null : _ctx.modelValue,
    options: _ctx.filteredOptions,
    class: "tm-field-autocomplete",
    size: _ctx.size,
    "emit-value": "",
    "map-options": "",
    "use-input": _ctx.useInput,
    autofocus: _ctx.autofocus,
    multiple: _ctx.multiple,
    "input-debounce": "0",
    "popup-content-class": _ctx.computedPopupContentClass,
    autocomplete: "none",
    onFilter: _ctx.filterFn,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
        ])
      }
    })
  ]), 1032, ["model-value", "options", "size", "use-input", "autofocus", "multiple", "popup-content-class", "onFilter"]))
}