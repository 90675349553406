import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-384ee1dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-field-number__wrap", {
      [`tm-field-number__wrap--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
      [_ctx.fieldClass]: _ctx.fieldClass
    }])
  }, [
    _createVNode(_component_q_input, _mergeProps({ ..._ctx.propsAndAttrs }, {
      "model-value": _ctx.modelValue,
      type: "number",
      class: ["tm-field-number", {
        'with-arrows': !_ctx.hideArrows,
      }],
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', +$event))),
      onBlur: _ctx.checkValue
    }), {
      prepend: _withCtx(() => [
        _renderSlot(_ctx.$slots, "prepend", {}, undefined, true)
      ]),
      append: _withCtx(() => [
        _renderSlot(_ctx.$slots, "append", {}, undefined, true)
      ]),
      hint: _withCtx(() => [
        _createElementVNode("div", null, [
          (_ctx.errorState)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "mb-1 body-text-regular-12 error--text",
                textContent: _toDisplayString(_ctx.errorHint)
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, _toDisplayString(_ctx.hint), 1)
        ])
      ]),
      _: 3
    }, 16, ["model-value", "class", "onBlur"]),
    (!_ctx.hideArrows)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["arrows", {
        'disable': _ctx.disable,
      }])
        }, [
          _createVNode(_component_tm_icon, {
            name: "expand_less",
            class: "pointer",
            onClick: _ctx.incrementValue
          }, null, 8, ["onClick"]),
          _createVNode(_component_tm_icon, {
            name: "expand_more",
            class: "pointer",
            onClick: _ctx.decrementValue
          }, null, 8, ["onClick"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}