
import { defineComponent } from 'vue'
import FieldSelectSearch from '@/components/shared/field/FieldSelectSearch.vue'
import { filterItemsByValues } from '@/services/utils'

export default defineComponent({
  name: 'FieldSelectSearchField',
  components: { FieldSelectSearch },
  props: {
    modelValue: {
      type: Object,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['hide', 'update:modelValue'],
  setup() {
    const filterFunction = (el: any, q: RegExp) => {
      if (el.subheader) return true
      const values = el.text ? [el.text] : [el.label]
      return filterItemsByValues(q, values)
    }

    return {
      filterFunction,
    }
  },
})
