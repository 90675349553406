
import type { PropType } from '@vue/runtime-core'
import { defineComponent, ref } from 'vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import useContactsOrganizationsOpenModal from '@/compositions/contacts/useContactsOrganizationsOpenModal'
import _ from 'lodash'
import type { AvatarSize, SizeProp } from '@/definitions/shared/types'

type FieldSelectOrganizationSize = 'small' | ''

export default defineComponent({
  components: {
    TmAvatar,
    FieldAutocomplete,
    TmButton,
    TmTooltip,
  },
  props: {
    modelValue: {
      type: Object,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionSearch: {
      type: String,
      default: 'name',
    },
    placeholder: {
      type: String,
      default: 'Add organization',
    },
    size: {
      type: String as PropType<FieldSelectOrganizationSize>,
      default: '',
    },
    avatarSize: {
      type: String as SizeProp<AvatarSize>,
      default: 'medium',
    },
    showLink: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    const fieldAutocomplete = ref()
    const isFocused = ref(false)
    const { openOrganizationsModal } = useContactsOrganizationsOpenModal()
    const blur = () => {
      fieldAutocomplete.value.blur()
    }
    return {
      fieldAutocomplete,
      isFocused,
      openOrganizationsModal,
      blur,
      kebabCase: _.kebabCase,
    }
  },
})
