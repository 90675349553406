
import type { PropType } from 'vue'
import { computed, defineComponent, ref, watch, onMounted } from 'vue'
import type { FieldSelectSize } from '@/components/shared/field/FieldSelect.vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import { escapeText } from '@/services/utils'

export default defineComponent({
  name: 'FieldAutocomplete',
  components: { FieldSelect },
  props: {
    modelValue: {
      type: [String, Number, Array, Object],
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionSearch: {
      type: [String, Array] as PropType<string | string[]>,
    },
    autofocus: {
      type: Boolean,
    },
    useInput: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
    },
    startSearchFrom: {
      type: Number,
      default: 0,
    },
    popupContentClass: {
      type: String,
      default: '',
    },
    keepEmpty: {
      type: Boolean,
    },
    size: {
      type: String as PropType<FieldSelectSize>,
      default: '',
    },
  },
  emits: ['update:modelValue', 'filtered-options-changed'],
  setup(props, context) {
    const fieldAutocomplete = ref()
    // hide selected options on multiply select
    const computedOptions = computed(() => {
      if (!props.multiple || !props.modelValue) return props.options
      // when modelValue is array of objects
      if (props.optionSearch) {
        return props.options.filter((item: any) => {
          if (item.notForSearch) return true
          const searchKey = Array.isArray(props.optionSearch) ? props.optionSearch.find(optSearchItem => item[optSearchItem]) : props.optionSearch
          return searchKey && !(props.modelValue as Array<any>).find((elem: any) => elem[searchKey] === item[searchKey])
        })
      }
      return props.options.filter((item: any) => !(props.modelValue as any[]).includes(item))
    })

    const inputValue = ref('')
    const filteredOptions = computed(() => {
      if (!inputValue.value) {
        return computedOptions.value
      }

      const escapeInputValue = escapeText(inputValue.value)
      const query = new RegExp(escapeInputValue, 'i')
      const filteredOptions = computedOptions.value.filter((v:any) => {
        if (v.notForSearch) return true
        if (Array.isArray(props.optionSearch)) {
          const multipleValues = props.optionSearch.reduce((acc: string, item: string) => {
            return v[item] ? acc + v[item] : acc
          }, '')
          return query.test(multipleValues)
        } else if (typeof props.optionSearch === 'string') {
          return query.test(v[props.optionSearch])
        }
        return query.test(v)
      })

      // if options include subheaders(notForSearch) remove subheaders without relative options
      if (filteredOptions.find((item: any) => item.subheader)) {
        return filteredOptions.filter((option: any, index: number, arr: any[]) => {
          if (!option.subheader) return true
          // next element exists and it's not a subheader
          return arr[index + 1] && !arr[index + 1].subheader
        })
      }

      return filteredOptions
    })

    const filterFn = (val: string, update: any) => {
      update(() => {
        inputValue.value = val
      })
    }

    const computedPopupContentClass = computed(() => {
      let resultContentClass = props.popupContentClass
      if (inputValue.value.length < props.startSearchFrom) {
        resultContentClass += ' hide-select-dropdown'
      }
      return resultContentClass
    })

    watch(filteredOptions, () => {
      // update menu size if options count has changed
      fieldAutocomplete.value.refreshMenu()
      context.emit('filtered-options-changed')
    })

    // methods for using from parents
    const updateInputValue = (newValue: string) => {
      fieldAutocomplete.value.updateInputValue(newValue)
    }

    const focus = () => {
      fieldAutocomplete.value.focus()
    }

    const blur = () => {
      fieldAutocomplete.value.blur()
    }

    const hidePopup = () => {
      fieldAutocomplete.value.hidePopup()
    }

    const showPopup = () => {
      fieldAutocomplete.value.showPopup()
    }

    const add = (opt: any) => {
      fieldAutocomplete.value.add(opt)
    }

    const scrollTo = (index: number) => {
      fieldAutocomplete.value.scrollTo(index)
    }

    if (props.autofocus) {
      onMounted(showPopup)
    }

    return {
      inputValue,
      fieldAutocomplete,
      filterFn,
      filteredOptions,
      computedPopupContentClass,
      // methods for using from parents
      updateInputValue,
      blur,
      focus,
      hidePopup,
      showPopup,
      add,
      scrollTo,
    }
  },
})
