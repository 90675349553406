
import { defineComponent, ref } from 'vue'
import FieldAutocompleteDropdown from '@/components/shared/field/FieldAutocompleteDropdown.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    FieldAutocompleteDropdown,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    showAddOption: {
      type: Boolean,
    },
    addOptionLabel: {
      type: String,
      default: 'Add item',
    },
    itemValue: {
      type: String,
    },
    emptyOption: {
      type: Object,
      default: () => ({}),
    },
    sidebarPaddings: {
      type: Boolean,
    },
    oneLine: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:options'],
  setup(props, context) {
    const fieldTags = ref()
    const isFocused = ref(false)

    const query = ref('')
    const onInput = (val: string) => {
      query.value = val
    }
    const selectItem = (item: any) => {
      context.emit('update:modelValue', [...props.modelValue, item])
    }
    const getValue = (item: any) => props.itemValue ? item[props.itemValue] : item
    const isValidValue = (val: string) => (
      query.value && props.options.every((opt: any) => getValue(opt) !== val)
    )
    const createItem = (val: string) => {
      if (props.itemValue) {
        return { ...props.emptyOption, [props.itemValue]: val }
      }
      return val
    }
    const addNewOption = () => {
      fieldTags.value.focus()
      if (!isValidValue(query.value)) return

      const item = createItem(query.value)
      const options = [...props.options, item]
      context.emit('update:options', options)
      fieldTags.value.updateInputValue('')
      selectItem(item)
    }

    const focus = () => {
      fieldTags.value.fieldAutocompleteDropdown.fieldAutocomplete.qSelect.focus()
    }
    const blur = () => {
      fieldTags.value.blur()
    }

    return {
      focus,
      blur,
      fieldTags,
      isFocused,
      query,
      onInput,
      addNewOption,
    }
  },
})
