
import type { PropType } from 'vue'
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  getCurrentInstance,
  onUnmounted,
  nextTick
} from 'vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import type { ChipSize } from '@/definitions/shared/types'
import TmBadge from '@/components/shared/TmBadge.vue'
import kebabCase from 'lodash/kebabCase'

import { useScroll } from '@/compositions/rootScroll'
import { getScrollbarWidth } from '@/services/utils'

export const autocompleteDropdownProps = {
  modelValue: {
    type: Array,
    default: () : Array<any> => [],
  },
  options: {
    type: Array,
    default: () : Array<any> => [],
  },
  // required if options is array of objects
  itemValue: {
    type: String,
    default: '',
  },
  selectedItemIcon: {
    type: String,
    default: '',
  },
  selectedItemSize: {
    type: String as PropType<ChipSize>,
    default: '',
  },
  selectedItemRounded: {
    type: Boolean,
  },
  selectedItemWithAvatar: {
    type: Boolean,
  },
  oneLine: {
    type: Boolean,
  },
  newLineInput: {
    type: Boolean,
  },
  startSearchFrom: {
    type: Number,
    default: 1,
  },
}

export default defineComponent({
  components: {
    FieldAutocomplete,
    TmBadge,
  },
  props: autocompleteDropdownProps,
  emits: ['update:modelValue'],
  setup(props, context) {
    const { rootScroll } = useScroll()
    const uid = getCurrentInstance()?.uid
    const qMenu = ref<HTMLElement>()
    const fieldAutocompleteDropdown = ref()
    const menuOffset = ref([0, 0])

    const setMenuOffset = () => {
      if (!qMenu.value) {
        return
      }
      const { clientWidth: innerWidth } = document.body
      const { innerHeight } = window
      const parentReact = fieldAutocompleteDropdown.value?.$el.getBoundingClientRect()
      const inputRect = fieldAutocompleteDropdown.value?.$el.querySelector('input').getBoundingClientRect()
      const { width: menuWidth, height: menuHeight } = qMenu.value?.getBoundingClientRect()
      const additionalOffsetX = 12 // just to improve the look
      const additionalOffsetY = 4 // just to improve the look
      const scrollbarWidth = getScrollbarWidth() // quasar uses this inside his position-engine

      const relativeLeft = parentReact?.left - inputRect?.left
      const relativeTop = Math.abs(parentReact?.top - inputRect?.top)

      let axisX
      if (innerWidth < inputRect?.left + menuWidth) {
        // does not allow to go beyond the right border
        axisX = relativeLeft + (inputRect?.left + menuWidth - innerWidth + additionalOffsetX)
      } else {
        axisX = relativeLeft
      }

      let axisY
      if (innerHeight < inputRect?.top + inputRect?.height + additionalOffsetY + scrollbarWidth + menuHeight) {
        /* does not allow to go beyond bottom border
          (native quasar behavior, menu sticky to top border if it doesn't fit completely top or bottom) */
        axisY = -relativeTop + menuHeight + additionalOffsetY
      } else {
        axisY = -relativeTop - inputRect?.height - additionalOffsetY
      }

      menuOffset.value = [axisX, axisY]
    }

    const filteredOptionsChanged = async () => {
      await new Promise((resolve) => setTimeout(resolve, 15)) // necessary delay for correct display 15 stable work
      render()
    }

    const render = () => {
      setMenuOffset()
      scrollTo(0)
    }

    const removeItem = (removeIndex: number) => {
      context.emit('update:modelValue', props.modelValue?.filter((value, index) => index !== removeIndex))
    }
    const popupShow = async () => {
      await nextTick()
      qMenu.value = document.querySelector(`.q-menu-${uid}`) as HTMLElement
      render()
    }

    onMounted(() => {
      rootScroll.value?.addEventListener('scroll', render)
    })

    onUnmounted(() => {
      rootScroll.value?.removeEventListener('scroll', render)
    })

    watch(
      () => props.modelValue,
      () => {
        fieldAutocompleteDropdown.value.updateInputValue('')
      }
    )

    // methods for using from parents
    const updateInputValue = (newValue: string) => {
      fieldAutocompleteDropdown.value.updateInputValue(newValue)
    }

    const focus = () => {
      fieldAutocompleteDropdown.value.focus()
    }

    const blur = () => {
      fieldAutocompleteDropdown.value.blur()
    }

    const showPopup = () => {
      fieldAutocompleteDropdown.value.showPopup()
    }

    const scrollTo = (index: number) => {
      fieldAutocompleteDropdown.value?.scrollTo(index)
    }

    return {
      uid,
      fieldAutocompleteDropdown,
      removeItem,
      menuOffset,
      updateInputValue,
      focus,
      blur,
      showPopup,
      popupShow,
      scrollTo,
      filteredOptionsChanged,
      kebabCase,
    }
  },
})
