import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe70ff18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!
  const _component_color_picker = _resolveComponent("color-picker")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createBlock(_component_tm_dropdown, {
    class: "d-block",
    "close-on-content-click": false
  }, {
    menu: _withCtx(() => [
      _createVNode(_component_color_picker, {
        "pure-color": _ctx.modelValue,
        "is-widget": true,
        format: _ctx.format,
        "disable-alpha": _ctx.format !== 'rgb',
        "onUpdate:pureColor": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
      }, null, 8, ["pure-color", "format", "disable-alpha"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_field, {
          "model-value": _ctx.hexValue,
          class: "field-color-picker"
        }, {
          prepend: _withCtx(() => [
            _createElementVNode("div", {
              class: "field-color-picker__current-color",
              style: _normalizeStyle({
              'background-color': _ctx.modelValue,
            })
            }, null, 4)
          ]),
          _: 1
        }, 8, ["model-value"])
      ])
    ]),
    _: 1
  }))
}