import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_skeleton = _resolveComponent("tm-skeleton")!
  const _component_validation = _resolveComponent("validation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tm-field", _ctx.wrapperClass])
  }, [
    _createVNode(_component_validation, {
      name: _ctx.name || _ctx.type,
      rules: _ctx.validators,
      "model-value": _ctx.modelValue
    }, {
      default: _withCtx(({ meta, handleBlur, handleChange }) => [
        (!_ctx.isLoading)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), _mergeProps({
              key: 0,
              ref: "field"
            }, { ..._ctx.$attrs, ..._ctx.propsWithoutClass }, {
              "model-value": _ctx.modelValue,
              class: ["tm-field__input-wrapper", {
          'tm-field-transparent': _ctx.transparent,
          'tm-field-error': _ctx.showError(meta),
          'tm-field-warning': _ctx.warningState,
          'tm-field-success': _ctx.successState,
          'tm-field-hide-message': _ctx.hideMessage,
        }],
              "error-state": _ctx.showError(meta),
              "error-hint": _ctx.errorMessage,
              hint: _ctx.message,
              "bottom-slots": _ctx.showError(meta) || !!_ctx.message,
              onBlur: handleBlur,
              "onUpdate:modelValue": handleChange
            }), _createSlots({ _: 2 }, [
              _renderList(_ctx.$slots, (_, name) => {
                return {
                  name: name,
                  fn: _withCtx((data) => [
                    _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
                  ])
                }
              })
            ]), 1040, ["model-value", "class", "error-state", "error-hint", "hint", "bottom-slots", "onBlur", "onUpdate:modelValue"]))
          : (_openBlock(), _createBlock(_component_tm_skeleton, {
              key: 1,
              width: "100%",
              height: "40px"
            }))
      ]),
      _: 3
    }, 8, ["name", "rules", "model-value"])
  ], 2))
}