
import type { PropType } from 'vue'
import { computed, defineComponent, nextTick, ref } from 'vue'
import FieldSearchSelect from '@/components/shared/field/FieldSearchSelect.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import type { FlagSize, SizeProp } from '@/definitions/shared/types'
import countryCodes from '@/definitions/_general/_data/contryPhoneCodes'

export default defineComponent({
  name: 'FieldPhone',
  components: { TmCountry, TmFlag, FieldSearchSelect },
  props: {
    previewMode: {
      type: Boolean,
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
    },
    flagSize: {
      type: String as SizeProp<FlagSize>,
      default: '',
    },
    autofocus: {
      type: Boolean,
    },
    sidebar: {
      type: Boolean,
    },
    narrow: {
      type: Boolean,
    },
    errorHintTeleport: {
      type: [String, Object] as PropType<string | HTMLElement>,
    },
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  setup(props, context) {
    const phoneRef = ref()
    const errorHintRef = ref()
    const phoneFocused = ref(false)
    const selectCountryFocused = ref(false)
    const focused = computed(() => phoneFocused.value || selectCountryFocused.value)
    // const isError = computed(() => !props.modelValue || props.modelValue.length < 10)
    const isError = computed(() => !props.modelValue)
    const isPreviewMode = computed(() => props.previewMode && !focused.value)
    const filterOptions = ['value', 'label']

    const countryCodeValue = ref('')
    const selectedCountryCode = computed(
      () => countryCodes.find((el: any) => el.value === countryCodeValue.value)
    )
    const init = () => {
      const matchGroups = (props.modelValue + ' ').match(/^\+(?<code>\d{1,3})\s/)?.groups
      countryCodeValue.value = matchGroups?.code || '1'

      if (!props.modelValue) {
        context.emit('update:modelValue', countryCodes[0].country.code)
      }
    }
    init()

    const inputPhone = (phone: string) => {
      context.emit('update:modelValue', phone)
    }

    const toggleDropdown = (val: any) => {
      selectCountryFocused.value = val
      if (!val) {
        nextTick(phoneRef.value.focus)
      }
    }
    const inputCode = (code: string) => {
      const newVal = props.modelValue.replace(countryCodeValue.value, code) || `+${code} `

      countryCodeValue.value = code

      inputPhone(`${newVal}`)

      toggleDropdown(false)
    }

    let disabledBlur = false
    const onBlur = () => {
      if (!disabledBlur) {
        phoneFocused.value = false
        context.emit('blur')
      }
    }
    const onFocus = () => {
      phoneFocused.value = true
      context.emit('focus')
    }
    const cancelBlur = () => {
      disabledBlur = true
      setTimeout(() => {
        disabledBlur = false
      }, 100)
    }

    const focus = () => { // fix bug from Field.vue (field.value.focus() )
      onFocus()
    }

    return {
      countryCodes,
      phoneRef,
      errorHintRef,
      isError,
      phoneFocused,
      selectCountryFocused,
      focused,
      isPreviewMode,
      filterOptions,
      selectedCountryCode,

      inputPhone,
      inputCode,
      onBlur,
      onFocus,
      cancelBlur,
      toggleDropdown,
      focus,
    }
  },
})
