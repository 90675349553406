import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, Teleport as _Teleport, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, renderList as _renderList, createSlots as _createSlots, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78d14299"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-phone" }
const _hoisted_2 = { class: "field-phone__option" }
const _hoisted_3 = { class: "font-weight-normal light--text" }
const _hoisted_4 = { class: "mt-1 mb-1 body-text-regular-12 error--text" }
const _hoisted_5 = { ref: "errorHintRef" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_field_search_select = _resolveComponent("field-search-select")!
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["field-phone__value", {
        'field-phone__value--focused': _ctx.focused,
        'field-phone__value--error': _ctx.isError,
      }])
    }, [
      _createVNode(_component_field_search_select, {
        options: _ctx.countryCodes,
        "model-value": _ctx.modelValue,
        narrow: _ctx.narrow,
        "hide-add-item": "",
        "map-options": "",
        "emit-value": "",
        "input-class": "field-phone__search-select",
        "popup-content-class": 'field-phone__search' + (_ctx.sidebar ? ' field-phone__search--sidebar': ''),
        "filter-options": _ctx.filterOptions,
        "onUpdate:modelValue": _ctx.inputCode,
        "onUpdate:dropdown": _ctx.toggleDropdown
      }, {
        "selected-item": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["field-phone__selected-item", {
              'pr-5px': !_ctx.isPreviewMode,
              'field-phone__selected-item--narrow': _ctx.narrow,
            }]),
            onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancelBlur && _ctx.cancelBlur(...args)))
          }, [
            _createVNode(_component_tm_flag, {
              class: "mr-2px",
              size: _ctx.flagSize,
              country: _ctx.selectedCountryCode.country
            }, null, 8, ["size", "country"]),
            (!_ctx.isPreviewMode)
              ? (_openBlock(), _createBlock(_component_tm_icon, {
                  key: 0,
                  name: "tmi-arrow-bottom",
                  class: "distinct--text"
                }))
              : _createCommentVNode("", true)
          ], 34)
        ]),
        option: _withCtx(({ opt }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_tm_country, {
              country: opt.country,
              class: "font-weight-normal mr-1"
            }, null, 8, ["country"]),
            _createElementVNode("span", _hoisted_3, "(+" + _toDisplayString(opt.value) + ")", 1)
          ])
        ]),
        _: 1
      }, 8, ["options", "model-value", "narrow", "popup-content-class", "filter-options", "onUpdate:modelValue", "onUpdate:dropdown"]),
      _createVNode(_component_tm_field, {
        ref: "phoneRef",
        "model-value": _ctx.modelValue,
        class: _normalizeClass(["field-phone__phone-body", {
          'ml-n2': _ctx.isPreviewMode,
          'field-phone__phone-body--narrow': _ctx.narrow,
        }]),
        placeholder: _ctx.placeholder,
        autofocus: _ctx.autofocus,
        "error-hint": "Please enter a valid phone number",
        validators: "min:11",
        "onUpdate:modelValue": _ctx.inputPhone,
        onFocus: _ctx.onFocus,
        onBlur: _ctx.onBlur
      }, _createSlots({
        hint: _withCtx(({errorState, errorHint}) => [
          errorState
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: _ctx.errorHintTeleport || _ctx.errorHintRef
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(errorHint), 1)
              ], 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        _renderList(_ctx.$slots, (_, name) => {
          return {
            name: name,
            fn: _withCtx((data) => [
              _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
            ])
          }
        })
      ]), 1032, ["model-value", "class", "placeholder", "autofocus", "onUpdate:modelValue", "onFocus", "onBlur"])
    ], 2),
    _createElementVNode("div", _hoisted_5, null, 512)
  ]))
}