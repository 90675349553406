
import { defineComponent } from 'vue'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import { FlagSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    FieldSelect,
    TmCountry,
  },
  props: {
    options: {
      type: Array,
      default: () => phoneCountryVariant,
    },
    flagSize: {
      type: String as SizeProp<FlagSize>,
      default: '',
    },
  },
})
