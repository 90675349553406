
import { defineComponent } from 'vue'
import FieldTags from '@/components/shared/field/FieldTags.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  name: 'FieldTagsRecipient',
  components: {
    TmAvatar,
    TmBadge,
    FieldTags,
  },
  props: {
    whiteBorderless: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: 'Choose list',
    },
  },
  setup() {
    const iconMap = {
      phone: { customIcon: 'phone', color: 'red' },
      list: { customIcon: 'tmi-people', color: 'mint' },
    }

    return {
      iconMap,
    }
  },
})
