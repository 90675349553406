
import { defineComponent } from 'vue'
import FieldSelectSearch from '@/components/shared/field/FieldSelectSearch.vue'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'

export default defineComponent({
  name: 'FieldSelectSearchTimezone',
  components: { FieldSelectSearch },
  setup() {
    return {
      utcZonesOptions,
    }
  },
})
