
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    leftLabel: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
    },
    alignStart: {
      type: Boolean,
      default: false,
    },
    fieldClass: {
      type: String,
    },
    darkActiveBackground: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, context) {
    const toggle = () => {
      context.emit('update:modelValue', !props.modelValue)
    }

    return {
      toggle,
    }
  },
})
