
import { computed, defineComponent } from 'vue'
import FieldSelectSearch from '@/components/shared/field/FieldSelectSearch.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import { getTableData } from '@/services/tableService'

const reportingCategories = getTableData('reportingCategories')

export default defineComponent({
  components: { TmAvatar, FieldSelectSearch, TmEllipsis },
  props: {
    hideAllAccounts: {
      type: Boolean,
    },
  },
  setup(props) {
    const filteredCategories = computed(() => {
      if (props.hideAllAccounts) { return reportingCategories.slice(1) }
      return reportingCategories
    })

    return {
      filteredCategories,
    }
  },
})
