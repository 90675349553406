
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import TmAssignee from '@/components/shared/TmAssignee.vue'
import TmUserPreview from '@/components/shared/TmUserPreview.vue'
import FieldAutocomplete from '@/components/shared/field/FieldAutocomplete.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmButton from '@/components/shared/TmButton.vue'
import _ from 'lodash'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import type { AvatarSize, SizeProp } from '@/definitions/shared/types'
import TmTooltip from '@/components/shared/TmTooltip.vue'

type FieldAssigneeSize = 'small' | ''

export default defineComponent({
  name: 'FieldAssignee',
  components: {
    TmAssignee,
    TmTooltip,
    FieldAutocomplete,
    TmUserPreview,
    TmAvatar,
    TmButton,
  },
  props: {
    modelValue: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: 'Enter assignee name',
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionSearch: {
      type: String,
      default: 'fullName',
    },
    withUnassignedOption: {
      type: Boolean,
      default: true,
    },
    showAddNew: {
      type: Boolean,
    },
    avatarSize: {
      type: String as SizeProp<AvatarSize>,
      default: 'medium',
    },
    size: {
      type: String as PropType<FieldAssigneeSize>,
      default: '',
    },
    showDropdownIcon: {
      type: Boolean,
    },
    emptyPlaceholder: {
      type: String,
      default: 'Unassigned',
    },
    showLink: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'openAddContactModal'],
  setup(props, { emit }) {
    const fieldAssignee = ref()
    const isFocused = ref(false)
    const unassignedValue = {
      fullName: props.emptyPlaceholder,
    }
    const { openModalNewContact } = useContactsOpenModal()

    const computedOptions = computed(() => props.withUnassignedOption ? [unassignedValue, ...props.options] : props.options)

    const onBlur = () => {
      if (!props.modelValue && props.withUnassignedOption) {
        emit('update:modelValue', unassignedValue)
      }
      isFocused.value = false
    }

    // public methods
    const focus = () => {
      fieldAssignee.value.focus()
      fieldAssignee.value.showPopup()
    }

    const blur = () => {
      fieldAssignee.value.blur()
    }

    const hidePopup = () => {
      fieldAssignee.value.hidePopup()
    }

    return {
      fieldAssignee,
      onBlur,
      focus,
      blur,
      hidePopup,
      isFocused,
      computedOptions,
      openModalNewContact,
      kebabCase: _.kebabCase,
    }
  },
})
