
import { defineComponent } from 'vue'
import type { SizeProp, IconSize } from '@/definitions/shared/types'
import FieldSelect from '@/components/shared/field/FieldSelect.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'

export default defineComponent({
  components: {
    FieldSelect,
    TmEllipsis,
  },
  props: {
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'xLarge',
    },
    icon: {
      type: String,
    },
    hideOptionIcon: {
      type: Boolean,
    },
  },
})
