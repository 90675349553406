import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, renderList as _renderList, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "d-flex align-center body-text-regular-14 neutral--text lh-20 w100pr" }
const _hoisted_2 = { class: "d-flex align-center body-text-regular-14 neutral--text lh-20 w100pr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_field_select = _resolveComponent("field-select")!

  return (_openBlock(), _createBlock(_component_field_select, null, _createSlots({
    "selected-item": _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_1, [
        (opt.icon || _ctx.icon)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 0,
              name: opt.icon ? opt.icon : _ctx.icon,
              class: "distinct--text mr-2",
              size: _ctx.iconSize
            }, null, 8, ["name", "size"]))
          : _createCommentVNode("", true),
        _createVNode(_component_tm_ellipsis, {
          class: "flex-grow-1",
          "min-width": "0px"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(opt.text ? opt.text : opt), 1)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_2, [
        ((opt.icon || _ctx.icon) && !_ctx.hideOptionIcon)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 0,
              name: opt.icon ? opt.icon : _ctx.icon,
              class: "distinct--text mr-2",
              size: _ctx.iconSize
            }, null, 8, ["name", "size"]))
          : _createCommentVNode("", true),
        _createVNode(_component_tm_ellipsis, {
          class: "flex-grow-1",
          "min-width": "0px"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(opt.text ? opt.text : opt), 1)
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
        ])
      }
    })
  ]), 1024))
}