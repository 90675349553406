import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_badge_editable = _resolveComponent("tm-badge-editable")!
  const _component_field_autocomplete = _resolveComponent("field-autocomplete")!

  return (_openBlock(), _createBlock(_component_field_autocomplete, {
    ref: "fieldEditableBadges",
    "model-value": _ctx.modelValue,
    options: [],
    multiple: "",
    class: _normalizeClass(["tm-field-editable-badges", {
      'tm-field-editable-badges--border-hidden': _ctx.borderHidden
    }]),
    "popup-content-class": "hide-select-dropdown",
    clearable: false,
    "hide-dropdown-icon": "",
    placeholder: _ctx.placeholder,
    "permanent-placeholder": "",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    onInputValue: _ctx.onInput,
    onNewValue: _ctx.addItem,
    onBlur: _ctx.onBlur
  }, {
    "selected-item": _withCtx(({ index, opt }) => [
      _renderSlot(_ctx.$slots, "selected-item", _normalizeProps(_guardReactiveProps({ index, opt })), () => [
        (_openBlock(), _createBlock(_component_tm_badge_editable, {
          key: opt.key,
          "model-value": opt.text,
          "margin-x-on": "",
          "margin-y-on": "",
          close: "",
          "forbidden-symbols": _ctx.itemDividerRegexp,
          error: !opt.valid,
          onClickClose: ($event: any) => (_ctx.removeItem(index)),
          "onUpdate:modelValue": ($event: any) => (_ctx.updateBadgeText($event, index))
        }, null, 8, ["model-value", "forbidden-symbols", "error", "onClickClose", "onUpdate:modelValue"]))
      ], true)
    ]),
    _: 3
  }, 8, ["model-value", "class", "placeholder", "onInputValue", "onNewValue", "onBlur"]))
}