
import { defineComponent } from 'vue'
import FieldEditableBadges from '@/components/shared/field/FieldEditableBadges.vue'
import { phoneRegexp } from '@/services/utils'

export default defineComponent({
  components: { FieldEditableBadges },
  setup() {
    return {
      phoneRegexp,
    }
  },
})
