import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_radio = _resolveComponent("q-radio")!

  return (_openBlock(), _createBlock(_component_q_radio, {
    class: _normalizeClass(["tm-field-radio", {
      'tm-field-radio--align-center': _ctx.alignCenter,
      'tm-field-radio--label-width-full': _ctx.labelWidthFull,
      [_ctx.fieldClass]: _ctx.fieldClass
    }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}