import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ea97d43"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-line" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "select-line__text" }
const _hoisted_4 = { class: "emphasize--text" }
const _hoisted_5 = { class: "emphasize--text ml-1" }
const _hoisted_6 = { class: "select-line" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = { class: "select-line__text" }
const _hoisted_9 = { class: "font-weight-semi-bold emphasize--text" }
const _hoisted_10 = { class: "distinct--text ml-1" }
const _hoisted_11 = { class: "border-top" }
const _hoisted_12 = { class: "d-flex align-center h40 px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_field_select_search = _resolveComponent("field-select-search")!

  return (_openBlock(), _createBlock(_component_field_select_search, {
    "model-value": _ctx.modelValue,
    "filter-function": _ctx.filterFunction,
    "subheader-style": "bold",
    "item-style": "regular",
    "option-label": "",
    options: _ctx.options,
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('hide'))),
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, _createSlots({
    "selected-item": _withCtx(({ opt }) => [
      _renderSlot(_ctx.$slots, "selected-item", { opt: opt }, () => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("img", {
            src: opt.image,
            alt: opt.label,
            class: "select-line__image"
          }, null, 8, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(opt.label), 1),
            _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(opt.uid) + ")", 1)
          ])
        ])
      ], true)
    ]),
    option: _withCtx(({ opt }) => [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("img", {
          src: opt.image,
          alt: opt.label,
          class: "select-line__image"
        }, null, 8, _hoisted_7),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(opt.label), 1),
          _createElementVNode("span", _hoisted_10, "(" + _toDisplayString(opt.uid) + ")", 1)
        ]),
        (opt.outlet)
          ? (_openBlock(), _createBlock(_component_tm_icon, {
              key: 0,
              name: "warning",
              color: "warning"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    "menu-footer": _withCtx(() => [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_tm_button, {
            "text-link": "",
            class: "primary--text",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('create-item')))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                class: "mr-2",
                name: "add"
              }),
              _createElementVNode("span", null, _toDisplayString(_ctx.buttonText), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
        ])
      }
    })
  ]), 1032, ["model-value", "filter-function", "options"]))
}